import React from 'react';
import lens from '../../assets/icons/lens.svg';
import StakeCard from './components/StakeCard';
import Navbar from '../../components/Navbar';
import { Wrapper, tw } from './style';
import Footer from '../../components/footer';
import { MdGridOn } from 'react-icons/md';
import { MdList } from 'react-icons/md';
import StakeModal from './components/Modal';
import {availableStake} from './dummyData'

// component
const Farm = ({ children, cog }) => {
  const [states, setStates] = React.useState({
    stake: false,
    stakeAmount: null,
    isSingle: true,
    cardData: {}
  });
  const [cardType, setCardType] = React.useState(true);

  function startStaking(cardData) {
    setStates({ ...states, stake: !states.stake });
  }

   function updateStakeAmount(e) {
    setStates({ ...states, stakeAmount: Number(e.target.value )});
    if(states.stakeAmount?.length === 0 && states?.stakeAmount === ''){
    setStates({ ...states, stakeAmount: null});

    }
    console.log(states.stakeAmount);
  }


  React.useEffect(() => {
    console.log(typeof (states.stakeAmount));
  })

    function scrollToTop() {
    window.scrollTo({
      top: 20,
      left: 0,
      behavior: 'smooth'
    });
  }


  React.useEffect(() => {
    scrollToTop()

  },[])


 

 
  return (
    <>
      <Navbar />
   {states.stake &&   <StakeModal
    click={startStaking}
    updateAmount={updateStakeAmount}
    amount={states.stakeAmount}
    />}
      <Wrapper className={tw.container}>
        {/* subnav */}
        <div className={tw.subnav}>
          <div className="tab  flex s-b midle bg-[#FAFAFA]">
            <button
              onClick={() => setStates({ ...states, isSingle: true })}
              className={` ${states.isSingle && 'active'} btn3 w-40`}
            >
              Stake Lp
            </button>
            <button
              onClick={() => setStates({ ...states, isSingle: false })}
              className={` ${!states.isSingle && 'active'} btn3 w-40`}
            >
              Single Token
            </button>
          </div>
          <div className="search_box  mx-4 h-full py-1">
            <div className={tw.inputField}>
              <input type="text" placeholder="Search Name or Symbol" className="field w-full h-full p-4" />{' '}
              <img role="button" src={lens} alt="" className="absolute right-4" />
            </div>
          </div>
          <div className="filter  p-1 midle">
            <button className="" onClick={() => setCardType(false)}>
              <MdGridOn className={`${!cardType && "text-pri-500"} text-2xl `} />
            </button>

            <button onClick={() => setCardType(true)} className={`${cardType && "text-pri-500"} text-3xl ml-4`} >
              <MdList />
            </button>
          </div>
        </div>

        {/*banner*/}
            <div className="banner mt-2 w-full relative ">
              <div className={tw.overlay}>
                <div className="  text-center text-white">
                  <h1 className="h2">Stake LP </h1>
                  <p className="mt-4 ">Publish and sell your health data in the marketplace.</p>
                </div>
              </div>
            </div>

            {/*market data*/}
            <React.Fragment>
              <div className=" my-6 p-6 w-full px-32 s-b">
                <div className="name">
                  <h1 className="header3">All Farm Pools</h1>
                </div>
                <div className="switch flex midle">
                  <p className="body2">Show staked pools only</p>

                  <div className="toggle ml-4">
                    <svg width="60" height="30" viewBox="0 0 60 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M44.584 0.416992H15.4173C7.36732 0.416992 0.833984 6.95033 0.833984 15.0003C0.833984 23.0503 7.36732 29.5837 15.4173 29.5837H44.584C52.634 29.5837 59.1673 23.0503 59.1673 15.0003C59.1673 6.95033 52.634 0.416992 44.584 0.416992ZM44.584 23.7503C39.7423 23.7503 35.834 19.842 35.834 15.0003C35.834 10.1587 39.7423 6.25033 44.584 6.25033C49.4257 6.25033 53.334 10.1587 53.334 15.0003C53.334 19.842 49.4257 23.7503 44.584 23.7503Z"
                        fill="#80858C"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className={`${cardType ? ' px-28' : 'grid grid-cols-2 gap-7 mt-8 px-28 '} `}>
                {cardType && (
                  <div className="list-nav flex  w-full p-4 justify-around">
                    <div className="box w-40"></div>
                    <div className="box">Liquidity</div>
                    <div className="box">APR</div>
                    <div className="box">Token Staked</div>
                    <div className="box">Your Reward</div>
                    <div className="box w-40"></div>
                  </div>
                )}
                {availableStake
                  .map((item, index) => (
                    <StakeCard
                      single={states.isSingle}
                      cardType={cardType}
                      click={startStaking}
                      withName={!states.isRibbon}
                      data={item}
                    />
                  ))}
              </div>
            </React.Fragment>

            <div className=" my-20  flex">
              <button className="btn1 w-48 mx-auto px-28 ">view more</button>
            </div>
          

        {/* footer */}
        <Footer />
      </Wrapper>
    </>
  );
};

export default Farm;
