import React from 'react';
// import { gtw } from '../../assets/styles';
// import logo from '../../assets/icons/Group 2782.svg';
import tw, { Wrapper, Box } from './styles';
import Navbar from '../../components/Navbar';
import calabar from '../../assets/icons/hero/calaba-right.png';
import calabal from '../../assets/icons/hero/calaba-left.png';
import bag from '../../assets/icons/hero/bag.png';
import lens from '../../assets/icons/hero/lens.png';
import fin from '../../assets/icons/hero/connect.png';
import farm from '../../assets/icons/hero/farm.png';
import swap from '../../assets/icons/hero/swap.png';
import mint from '../../assets/icons/hero/mint.png';
import liq from '../../assets/icons/hero/money.png';
import borrow from '../../assets/icons/hero/coin.png';
import section from '../../assets/icons/hero/section.png';
import patl from '../../assets/icons/hero/pat-l.png';
import patr from '../../assets/icons/hero/pat-r.png';
import { Link, useNavigate } from 'react-router-dom';

const cards = [
  {
    name: 'Market',
    color: '#F8D7E8',
    image: bag,
    details: 'Access and Buy Health information reports with GHF Tokens.',
    to: '/market'
  },
  {
    name: 'Publish',
    color: '#FFEBEB',
    image: lens,
    details: 'Publish and trade your health information reports as NFTs',
    to: '#'
  },
  {
    name: 'Finance',
    color: '#D6EFF6',
    image: fin,
    details: 'Invest in stocks and bonds of various Health Institutions.',
    to: '/finance'
  },
  {
    name: 'Farm',
    color: '#FDF3E5',
    image: farm,
    details: 'Stake liquidity pools and ribbon tokens to earn more APY.',
    to: '/farm'
  },
  { name: 'Swap', color: '#FDF3E5', image: swap, details: 'Swap a token for another using low fees exchanges.', to: '/swap' },
  {
    name: 'Mint AFYA',
    color: '#E5F5F8',
    image: mint,
    details: 'Mint AFYA directly in the Defi with any token you choose.',
    to: '#'
  },
  {
    name: 'Liquidity',
    color: '#ECF6DC',
    image: liq,
    details: 'Use Cypto Assets to join a liquidity pool and earn APY.',
    to: '/liquidity'
  },
  {
    name: 'Borrow & Lend',
    color: '#ECF6DC',
    image: borrow,
    details: 'Lend and borrrow cyptoassets easily with your tokens.',
    to:'/finance/borrow'
  }
];

const info = [
  {
    name: 'Health Data Market',
    details:
      'The Ribbon Protocol Healthcare Data Marketplace provides a standardized space to trade commercially viable health information in a secure and transparent way, where pricing is determined by supply and demand.'
  },

  {
    name: 'NTFs',
    details:
      'High value Health Information traded on the marketplace can be curated into sets or volumes, minted as NFTs, and traded on a secondary NFT marketplace. This will allow passive investors, who would otherwise have no need for the information, access to investments in Health Information.'
  },

  {
    name: 'Financing',
    details:
      'The Ribbon Protocol Financing tools provide individuals, institutions,  Healthcare providers and governments to use Tokens or  tokenized health information as a collateral asset to access decentralized finance for funding Healthcare infrastructure and health programs.'
  },

  {
    name: 'Integrated Exchanges',
    details:
      'The Ribbon Protocol integrates trusted Decentralized Exchanges such as Uniswap,  Pancakeswap and more to provide a secure and liquid market for users to exchange tokens.'
  }
];

const countArr = [
  {
    name: 'All Time Volume',
    count: 22
  },
  {
    name: 'Total Users',
    count: 11
  },
  {
    name: 'Total Value Locked',
    count: 11
  },
  {
    name: 'Liquidity',
    count: 11
  }
];

const Land = () => {
  const _N = useNavigate();
  return (
    <Wrapper className={tw.wrapper}>
      <Navbar />
      <div className="land">
        <section className="hero relative">
          <div className="content mx-auto p-0 fadeIn text-center mb-16 pt-36">
            <h1 className=" w-full main font-aexb">
              A Liquid Marketplace For Commercially Valuable Health Information
            </h1>
            <p className="mx-auto font-al mt-2.5">
              A Decentralized Finance platform for Global Health and Public Health financing to bridge the global health
              financing gap and for trading health data.{' '}
            </p>

            <button className="btn1 font-am mt-10" id="connect">
              Connect to wallet
            </button>
          </div>

          <img src={calabar} alt="" className="absolute bottom-0 right-0" />
          <img src={calabal} alt="" className="absolute -bottom-8 left-0" />

          {/* Ribbon features*/}

          <div className="features mx-auto px:0 md:px-20 lg:px-40 text-center">
            <h1 className=" font-aexb text-black main">Ribbon Features</h1>
            <p className="b1 mx-auto font-abk mt-3">
              Ribbon's DeFi platform enables Holders of Trillions worth of Cryptocurrencies to...
            </p>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mt-8">
              {cards.map((item, index) => (
                <Link to={item.to}>
                  <Box
                    className="box p-4 bg-white text-center rounded-xl shadow-sm cursor-pointer"
                    key={index}
                    bcl={item.color}
                  >
                    <img src={item.image} alt="" className="mx-auto" />
                    <h1 className="text-[#1A222F] font-abld text-xl mt-2">{item.name}</h1>
                    <h1 className="text-[#4D545D] font-abk text-sm mt-2">{item.details}</h1>
                  </Box>
                </Link>
              ))}
            </div>
          </div>
        </section>

        <section className="details bg-[#F7FAFF] mx-auto px:0 md:px-20 lg:px-40 md:pt-32 -mt-16">
          <div className="flex items-center gap-x-16 justify-between">
            <div className="lists">
              <div className="list-cont">
                {info.map((item, index) => (
                  <div className="item my-8" key={index}>
                    <h1 className="text-[#1A222F] font-abld text-2xl">{item.name}</h1>
                    <p className="text-[#4D545D] font-abk text-sm mt-4">{item.details}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="img">
              <img src={section} alt="img" className="section_img" />
            </div>{' '}
          </div>
        </section>

        <section className="protocol h-auto flex flex-col pt-14 items-center justify-start">
          <h1 className="h1 font-aexb">Protocol Analytics</h1>
          <div className="counts s-b mt-4">
            {countArr.map((item, index) => (
              <div className=" p-1 text-center">
                <h1 className="grad font-aexb text-4xl">$ {item.count} M</h1>
                <p className=" b2">{item.name}</p>
              </div>
            ))}
          </div>

          <div className=" relative w-full mt-36">
            <div className="card relative mx-auto bg-white p-9 rounded-xl shadow flex flex-col justify-center items-center">
              <h1 className="h3">Start Publishing, Trading and Earning Now</h1>
              <button className="btn1 font-am mt-6">Connect to wallet</button>
            </div>
            <img src={patl} alt="" className="absolute img-one" />
            <img src={patr} alt="" className="absolute img-two" />
          </div>
        </section>

        <footer className="bg-black text-[#4D545D]  p-14 midle justify-center">
          <div className="text_box h-auto">
            <h1 className="h3 font-abld">Ribbon Protocol</h1>
            <p className="mt-6 font-abk w-2/3">
              Aliquip occaecat labore reprehenderit enim pariatur laborum aliqua tempor.{' '}
            </p>
            <p className="b2">Copyright 2021 | Ribbon Potocol</p>
          </div>
          <div className="nav">
            <ul className="top flex">
              <li className="item capitalize">About</li>
              <li className="item capitalize ml-3">Contact Us</li>
              <li className="item capitalize ml-3">Docmentation</li>
              <li className="item capitalize ml-3">About</li>
            </ul>
          </div>
        </footer>
      </div>
    </Wrapper>
  );
};

export default Land;
