import styled from 'styled-components';
import React from 'react';
import uni from '../../../assets/icons/UNI - Uniswap.svg';
import cake from '../../../assets/icons/CAKE - PancakeSwap Token.png';
import solana from '../../../assets/icons/SOL - Solana.svg';
import { NavLink } from 'react-router-dom';

const swapList = [
  {
    name: 'uniswap',
    img: uni,
    color: '#FEE0EE',
    tclr: false,
    path: '/'
  },

  {
    name: 'PancakeSwap',
    img: cake,
    color: '#4CD7DD',
    tclr: false,
    path: '/'
  },
  {
    name: 'solana',
    img: solana,
    color: '#7A55A8',
    path: '/',
    tclr: true
  }
];

const SwapCard = ({ children, cog }) => {
  return (
    <Wrapper className={tw.container}>
      <div className="label_list"></div>

      {swapList?.map((item, index) => (
        <Nav
          as={NavLink}
          bgc={item.color}
          to={item.path}
          key={index}
          clr={item.tclr.toString()}
          className={tw.label}
        >
          <div className="txt">
            <p className="caption">Swap with</p>

            <h1 className="name">{item.name}</h1>
          </div>

          <div className="img w-12 h-12 rounded-md">
            <img src={item.img} alt="" className="rounded-md" />
          </div>
        </Nav>
      ))}

      <div className="bellow  p-2 rounded mt-6">
        <div className="inner_block p-2">
          <h1 className="capitalize">
            Lorem ipsum dolor sit amet , elit.
            <br />
            Deleniti iusto omnis nemo quo. Quia sunt alias{' '}
          </h1>
        </div>
      </div>
    </Wrapper>
  );
};

// tailwind styles
const tw = {
  container: 'w-full h-full  p-4 mt-4 strictFadeIn',
  label: 'label s-b p-4 py-9 h-14 bg-red-400 my-3 radius'
};

// styled-c
const Wrapper = styled.section`
  /* color: #1a222f; */
  .bellow {
    background: #fafafa;
  }
  .inner_block {
    border-left: 3px solid #d90d5a;
  }
  a {
    /* background: red; */
    background: ${({ bgc }) => bgc || ''};
  }
`;

const Nav = styled.div`
  /* background: red; */
  background: ${({ bgc }) => bgc || ''};
  color: ${({ clr }) => (clr === 'true' ? '#fff' : '#1a222f')};
  .caption {
    font-size: 14px;
    /* font-weight: medium; */
  }

  .name {
    font-size: 18px;
    font-weight: medium;
  }
`;

export default SwapCard;
