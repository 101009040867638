 export const availableStake = [
    {
      name:'',
      enabled: false
    },
       {
      name:'',
      enabled: false
    },   {
      name:'',
      enabled: false
    },   {
      name:'',
      enabled: true
    },   {
      name:'',
      enabled: false
    },   {
      name:'',
      enabled: false
    },   {
      name:'',
      enabled: false
    },
      {
      name:'',
      enabled: true
    },   {
      name:'',
      enabled: true
    },  
      {
      name:'',
      enabled: true
    },  {
      name:'',
      enabled: true
    },   {
      name:'',
      enabled: true
    }, 
     {
      name:'',
      enabled: false
    },  {
      name:'',
      enabled: false
    }, 
      {
      name:'',
      enabled: true
    },  {
      name:'',
      enabled: false
    }, 
      {
      name:'',
      enabled: true
    }, 
  ]
