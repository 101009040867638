// import styled from 'styled-components';
import React from 'react';

// import AllRoute from '../../router';
import Card from './components/Card';
// import { NavLink } from 'react-router-dom';
// import Swapcard from './components/SwapCard'
// import Mint from './components/mint'
import Layout from '../../components/Layout';

const Dash = ({ swapping }) => {
  return (
    <Layout>
      <section className="w-full ctd h-full">
        {/*TODO: card component*/}
        <Card />
      </section>
    </Layout>
  );
};

export default Dash;
