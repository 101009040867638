import styled from 'styled-components';
import React from 'react';
import caret from '../../../../assets/icons/carret.svg';
import swaps from '../../../../assets/icons/updw.svg';
import cake from '../../../../assets/icons/CAKE - PancakeSwap Token.png';
import StatusLoader from '../../../../components/modals/statusLoader';
import { ToastContainer,  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { getUserWallet } from '../../../../redux/slice/authSlice';
import {  useSelector } from 'react-redux';

// import { loadingStatus } from '../../../../redux/slice/authSlice';

const Mint = ({ children, cog }) => {
  const [showLoader, setShowLoader] = React.useState(false);
  // const [states, setStates] = React.useState({ connected: true, minting: true });
  // const _D = useDispatch();
  const userState = useSelector((state) => state.auth);
  const arr = Array(8).fill(8);

  // constants
  let User = userState.user
  // constants

  // functions

  // const Success = () => {
  //   toast.success('Approved Transaction In Wallet', {
  //     position: 'top-right',
  //     autoClose: 3000,
  //     hideProgressBar: true,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined
  //   });
  // };

  // function doSome() {
  //   // TODO: get userdetails
  //   console.log();
  //   _D(getUserWallet);
  // }
  
  function Mint() {
  setShowLoader(true)
  
  }

  // functions

  return (
    <>
      {showLoader && <StatusLoader toggleModal={setShowLoader} />}
      <ToastContainer />
      <Wrapper className={tw.container}>
        <div className="from  px-9 p-2">
          <div className="flex justify-between mt-3">
            <div className="box">
              <h1 className="capitalize">from</h1>
              <Dropdown className="midle p-1 mt-1 px-3 bg-sec-100 relative">
                <h1 className="select text">select Token</h1>
                <img src={caret} alt="" className="ml-2" />
                <div className="tokens absolute ">
                  {arr.map((item, index) => (
                    <div key={index} className="token border midle my-1">
                      <img src="" alt="" className="border w-6 h-6" />
                      <h1 className=" uppercase ml-2">eth</h1>
                    </div>
                  ))}
                </div>
              </Dropdown>
            </div>

            <div className="ima_box w-9 h-9">
              <img src={cake} alt="" />
            </div>
          </div>

          <span className="s-b mt-1">
            <span className="empty"></span>
            <h1 className="empty text">balance: 2.3434 CAKE</h1>
          </span>

          <div className="input_cont  h-11 mt-2">
            <input placeholder="0.0" type="text" name="" className="field w-full h-full bg-pri-50 p-3" id="" />
          </div>
        </div>
        <div className="swap ctd">
          <div className="circle ctd bg-red-50 rounded-full mt-1 w-9 h-9">
            <img src={swaps} alt="" />
          </div>
        </div>

        <div className="to px-9 p-2">
          <div className="flex justify-between">
            <div className="box">
              <h1 className="capitalize">to</h1>
              <Dropdown className="midle p-1 px-3 mt-1 bg-gray-200">
                <h1 className="select text">AFYA</h1>
              </Dropdown>
            </div>

            <div className="ima_box w-9 h-9">
              <img src={cake} alt="" />
            </div>
          </div>

          <div className="input_cont  h-11 mt-2">
            <input placeholder="0.0" type="text" name="" className="field w-full h-full bg-pri-50 p-3" id="" />
          </div>
        </div>
        {User.connected ? (
          <div className="button w-full mt-9 s-b">
            {' '}
            <button disabled={!true} onClick={() => setShowLoader(true)} className="btn1 w-full txt-blu-1">
              approve
            </button>
            <div className="w-16"></div>
            <button disabled={!true} onClick={Mint} className="btn1 w-full txt-blu-1">
              mint
            </button>
          </div>
        ) : (
          <button disabled={!true} onClick={() => setShowLoader(true)} className="btn2 w-full txt-blu-1 mt-9">
            connect to wallet
          </button>
        )}
      </Wrapper>
    </>
  );
};


const tw = {
  container: 'w-full h-full strictFadeIn '
};

const Wrapper = styled.main`
  .field {
    &:focus {
      outline: none;
    }
  }
`;
const Dropdown = styled.button`
  /* background-color: #fee7ef; */

  &:hover {
    .tokens {
      background-color: #fff;
      width: 140px;
      height: auto;
      top: 38px;
      left: 0;
      display: block;
    }
  }
  .tokens {
    background-color: #fff;
    width: 140px;
    height: 254px;
    top: 38px;
    left: 0;
    display: none;
  }
`;

export default Mint;
