import styled from 'styled-components';
import React from 'react';
// import book from '../../../../../assets/images/market/books.png';
// import avatar from '../../../../../assets/images/market/pub.png';
import caret from '../../../../../assets/icons/carret.svg';

// import ProductCard from '../../../card.jsx';
import StatusLoader from './Modal';
// import { isFulfilled } from '@reduxjs/toolkit';
// import StatusLoader from '../../../../../components/modals/statusLoader'

const LendOptions = ({ isRibbon, toggleShowCardDetails, mode }) => {
  const [states, setStates] = React.useState({ isLending: !true, loading: false });
  // const _N = useNavigate();

  function toggleModal() {
    setStates({ ...states, isLending: !states.isLending });
  }

  // const address = 'ROVJGISIGIHIJFJSIFIGPISPIFGIFYYOIYOINOUH';

  return (
    <React.Fragment>
      {states.isLending && <StatusLoader toggleModal={toggleModal} />}
      <Wrapper className={tw.container}>
        <div className="nav mx-auto w-10/12 flex-start">
          <button onClick={() => toggleShowCardDetails()} className="midle">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M20 8.75H4.7875L11.775 1.7625L10 0L0 10L10 20L11.7625 18.2375L4.7875 11.25H20V8.75Z"
                fill="#4D545D"
              />
            </svg>
            <p className="ml-3 capitalize text-xl">go back</p>
          </button>
        </div>

        <div className={tw.card}>
          {!mode && (
             <div className="user_details bg-[#fafcff] w-full rounded-xl  h-full p-4">
            <div className="main_list mt-8 my-6 items-center grid grid-cols-4 gap-4 p-6">
              <div className="item s-b  flex flex-col w-full h-full">
                <div className="row_one s-b w-full">
                  <h1 className="body4">Liquidity</h1>
                  <h1 className="text">2.90%</h1>
                </div>

                <div className="row_two s-b w-full">
                  <h1 className="body4">Liquidity</h1>
                  <h1 className="text">2.90%</h1>
                </div>
              </div>

              <div className="item s-b  flex flex-col w-full h-full">
                <div className="row_one s-b w-full">
                  <h1 className="body4">Walllet Balance</h1>
                  <h1 className="text">2.90%</h1>
                </div>

                <div className="row_two s-b w-full">
                  <h1 className="body4">300.00 RBN</h1>
                  <h1 className="text">2.90%</h1>
                </div>
              </div>

              <div className="item s-b  flex flex-col w-full h-full">
                <div className="row_one s-b w-full">
                  <h1 className="body4">Liquidity</h1>
                  <h1 className="text">2.90%</h1>
                </div>

                <div className="row_two s-b w-full">
                  <h1 className="body4">Liquidity</h1>
                  <h1 className="text">2.90%</h1>
                </div>
              </div>

              <div className="item s-b  flex flex-col w-full h-full">
                <div className="row_one s-b w-full">
                  <h1 className="body4">Liquidity</h1>
                  <h1 className="text">2.90%</h1>
                </div>

                <div className="row_two s-b w-full">
                  <h1 className="body4">Borrow APY</h1>
                  <h1 className="text">2.90%</h1>
                </div>
              </div>
            </div>
          </div>
          )}
          {/* middle */}
          <div className={tw.details}>
            <div className="from  px-9 p-2">
              <div className="flex justify-between mt-3">{mode ? 'Withdraw Deposit' : 'Deposit'}</div>
              {mode ? (
                <div className="flex mt-3 items-center  ctd">
                  <h1 className="body1">Current Balance: </h1>
                  <p className="text ml-4">300.00 RBN</p>
                </div>
              ) : (
                <p className="body1 mt-2">Lend out your asset and earn</p>
              )}

              <span className="s-b mt-12">
                <span className="empty"> Enter Lend Amount:</span>
                {!mode && (
                  <h1 className="empty text">
                    <span className="body1">Balance</span>: 4.3434 RBN
                  </h1>
                )}
              </span>

              <div className="input_cont relative  h-11 mt-2">
                <input placeholder="0.0" type="text" name="" className="field w-full h-full bg-pri-50 p-3" id="" />

                <div className="btn_liq_ghost_auto btn_liq_ghost max_btn absolute top-2">Max</div>

                <div className=" absolute right-0 top-0  ">
                  <Dropdown className="midle p-1 h-11 px-4 bg-sec-100 relative">
                    {SvgRibbonSm}
                    <h1 className="select text ml-1">RBN</h1>
                    <img src={caret} alt="" className="ml-3" />

                    <div className="tokens absolute "></div>
                  </Dropdown>
                </div>
              </div>
       <p className="body1 mt-11">You will Recieve AFYA</p>
            </div>

        {mode ? (
          <div className="w-full mt-6">
             <button disabled={true} className="btn1 w-full txt-blu-1">
               withdraw
              </button>
          </div>
        ) :(    <div className="button_box mt-11 s-b p-6  ">
              {' '}
              <button disabled={!true} onClick={toggleModal} className="btn1 w-full txt-blu-1">
                Supply
              </button>
              <div className="w-16"></div>
              <button disabled={true} className="btn1 w-full txt-blu-1">
                Add Liquidity
              </button>
            </div>)}
          </div>
        </div>
      </Wrapper>
    </React.Fragment>
  );
};

const SvgRibbonSm = (
  <svg width="25" height="25" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle r="25" transform="matrix(1 0 0.00750483 0.999972 25.1876 24.9993)" fill="#4285F4" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.5561 28.6135H19.715L19.7695 35.8878H20.2833C20.8425 35.8878 21.2991 36.341 21.3033 36.9002C21.3075 37.4593 20.8576 37.9126 20.2985 37.9126H19.7847L19.7861 38.0993C19.7967 39.5123 18.8554 40.7033 17.5618 41.0682C17.3803 41.3266 17.0799 41.4952 16.7389 41.4952C16.4014 41.4952 16.1012 41.33 15.9153 41.0761C14.6018 40.7207 13.6286 39.5226 13.6179 38.0993L13.6165 37.9126H13.1334C12.5742 37.9126 12.1175 37.4593 12.1133 36.9002C12.1091 36.341 12.559 35.8878 13.1182 35.8878H13.6013L13.4836 20.197C13.4354 13.7741 18.6032 8.56738 25.0262 8.56738H28.1159C34.0505 8.56738 37.7715 12.4925 37.8178 18.6606C37.8515 23.1464 35.821 26.5575 31.8125 27.9126L36.3331 36.7585C37.3704 38.7883 35.9129 41.1833 33.6401 41.1833C32.4692 41.1833 31.3964 40.5134 30.879 39.4592L25.5561 28.6135ZM19.6048 13.9411L23.9064 26.2505L19.6048 28.6135C20.8909 27.9419 22.4022 27.1272 23.9064 26.2505C27.6942 24.0427 31.4368 21.441 31.424 19.7353L31.4079 17.5858C31.39 15.2027 29.9319 13.9411 27.5488 13.9411H19.6048Z"
      fill="#F7FAFF"
    />
  </svg>
);

// tailwind styles
const tw = {
   container: 'bg-white relative rounded-xl  relative  p-6 mb-20 ',
  card: 'w-10/12  mx-auto p-4 flex flex-col items-center justify-center strictFadeIn rounded-xl mt-11',
  details: 'w-1/2 border border-pri-300 rounded-xl h-full p-4 py-11 ml-2 strictFadeIn lendcard',
  media: 'img_box strictFadeIn rounded-2xl ',
  publisher: 'midle',
  action: 'midle my-6',
  address: ' w-10/12 my-11 p-6 mx-auto s-b',
  copy: 'w-60 '
};

// styled-c
const Wrapper = styled.div`
  .img_box {
    width: 450px;
    height: 450px;
  }
  .thumbnail {
    width: 30%;
  }

  .cto_btn {
    margin-left: 10%;
  }

  .user_details {
    height: auto;
    width: 100%;
  }
  .lendcard {
    height: auto;
  }

  .item {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .button_box {
    /* margin-top: 10%; */
  }

  .max_btn {
    left: 40%;
  }

  .collateral-card {
  }
`;

const Dropdown = styled.button`
  &:hover {
    .tokens {
      background-color: #fff;
      width: 140px;
      height: auto;
      top: 38px;
      left: 0;
      display: block;
    }
  }
  .tokens {
    background-color: #fff;
    width: 140px;
    height: 254px;
    top: 38px;
    left: 0;
    display: none;
  }
`;
export default LendOptions;

// {arr.map((item, index) => (
//                   <div key={index} className="token border midle my-1">
//                     <img src="" alt="" className="border w-6 h-6" />
//                     <h1 className=" uppercase ml-2">eth</h1>
//                   </div>
//                 ))}
