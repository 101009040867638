import styled from 'styled-components';
import React from 'react';
import timesW from '../../../assets/icons/timesW.svg';
import logo from '../../../assets/icons/Group 2782.svg';

import MoonLoader from 'react-spinners/MoonLoader';

const CardLayout = ({ toggleModal, status, done, confirm }) => {
  const [states, setStates] = React.useState({ confirming: true, loading: true });



  React.useEffect(() => {
    setTimeout(() => {
      setStates({ ...states, confirming: false, loading: false });
    }, 2000);
  });

  return (
    <Wrapper className={tw.container}>
      {states.loading ? <Loader toggleModal={toggleModal} /> : <Success done={done} toggleModal={toggleModal} />}
    </Wrapper>
  );
};

function Loader({ toggleModal }) {
  return (
    <div className="card bg-white rounded h-48 w-48 radius p-9  flex flex-col justify-between fadeIn">
      <div className="action s-b p-1">
        <p className="text-white capitalize"></p>
        <button className="times" onClick={() => toggleModal(false)}>
          <img src={timesW} alt="" />
        </button>
      </div>

      <div className="w-full h-auto  my-auto flex flex-col items-center text-[#1A222F]">
        <div className="loader  ctd relative ">
          <img src={logo} alt="" className="w-11 h-11 absolute" />
          <MoonLoader color="#4286f4" size={80} />
        </div>

        <p className="conf text-[#676C74] text-sm">Please wait while we creaate bond pool</p>
      </div>
    </div>
  );
}

function Success({ toggleModal, done }) {



  return (
    <div className="card bg-white rounded h-48 w-48 radius p-9  flex flex-col justify-between fadeIn">
      <div className="action s-b p-1">
        <p className="text-white capitalize"></p>
        <button className="times" onClick={() => toggleModal(false)}>
          <img src={timesW} alt="" />
        </button>
      </div>

      <div className="w-full h-auto  flex flex-col items-center text-[#1A222F]">
        <p className="h3  mt-11">HIV variant Bond Created</p>

        <button onClick={done} className="btn1 text-[#676C74] w-full mt-4">
          join pool
        </button>
      </div>
    </div>
  );
}

// tailwind styles
const tw = {
  container: 'h-screen w-screen ctd strictFadeIn'
};

// styled-c
const Wrapper = styled.main`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  background: #0007;

  .card {
    width: 500px;
    height: 400px;

    .loader {
      width: 150px;
      height: 150px;

      img {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
`;

export default CardLayout;
