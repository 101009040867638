import React from 'react';
import Card from './components/Card';
import Layout from '../../components/Layout';

const Dash = ({ swapping }) => {
  return (
    <Layout auto = {true}>
      <section className="w-full  h-full">
        <Card />
      </section>
    </Layout>
  );
};

export default Dash;
