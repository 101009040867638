import styled from 'styled-components';
import React from 'react';


const Footer = () => {
  // const [states, setStates] = React.useState(null);

  return (
    <Wrapper className="bg-black text-[#4D545D]  p-14 midle justify-center">
          <div className="text_box  h-auto">
            <h1 className="h3">Ribbon Protocol</h1>
            <p className=" mt-11 w-2/3">
              Aliquip occaecat labore reprehenderit enim pariatur laborum aliqua tempor.{' '}
            </p>
            <p className="b2">Copyright 2021 | Ribbon Potocol</p>
          </div>
          <div className="nav">
            <ul className="top flex">
              <li className="item capitalize">About</li>
              <li className="item capitalize ml-3">Contact Us</li>
              <li className="item capitalize ml-3">Docmentation</li>
              <li className="item capitalize ml-3">About</li>
            </ul>
          </div>
    </Wrapper>
  );
};



// styled-c
const Wrapper = styled.footer`
  height:368px;
`;

export default Footer;
