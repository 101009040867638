import React from 'react';
import Landing from './pages/landing';
// import Dashboard from './dashboard';
import { Routes, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
// import { Routes, Route } from 'react-router-dom';
import Swap from './pages/swap';
import Market from './pages/market';
import Farm from './pages/Farm';
import Finance from './pages/Finance';
import Lend from './pages/Finance/Lend';
import Borrow from './pages/Finance/borrow';
import Bond from './pages/Finance/bonds';
import Synthetic from './pages/Finance/syntetics';
import Liquidity from './pages/liquidity';
import ScrollToTop from './components/scrollToTop';

//TODO: brouser resize method
//TODO: redirect to dashboard.

function App() {
  const [states, setStates] = React.useState({ loading: true, showLanding: true });

  React.useEffect(() => {
    setTimeout(() => {
      setStates({ ...states, showLanding: false });
    }, 1500);
  }, [states]);

  return (
    <AnimatePresence>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/swap" element={<Swap />} />
          <Route path="/market" element={<Market />} />
          <Route path="/farm" element={<Farm />} />
          <Route path="/finance" element={<Finance />}>
            <Route index element={<Lend />} />
            <Route path="borrow" element={<Borrow />} />
            <Route path="lend" element={<Lend />} />
            <Route path="bond" element={<Bond />} />
            <Route path="synthetic" element={<Synthetic />} />
            <Route path="*" element={<Landing />} />
          </Route>
          <Route path="/liquidity" element={<Liquidity />} />
          <Route path="*" element={<Landing />} />
        </Routes>
      </ScrollToTop>
    </AnimatePresence>
  );
}

export default App;
