import styled from 'styled-components';
import React from 'react';
// import cake from '../../../../../assets/icons/CAKE - PancakeSwap Token.png';
// import times from '../../../../../assets/icons/times.svg';
import timesW from '../../../../assets/icons/timesW.svg';
import rib from '../../../../assets/icons/liquidity/rib.svg';
import eth from '../../../../assets/icons/liquidity/eth.svg';
// import logo from '../../../../../assets/icons/Group 2782.svg';

// import { useNavigate } from 'react-router-dom';
import CardLayout from './confirmationModal';
// import {useOnClickOutside} from '../../../../../hooks/useOutsideClick'
// import { Portal } from 'react-portal';
// import { Oval } from 'react-loader-spinner';
// import { css } from '@emotion/react';

const StakeModal = ({ click, updateAmount, amount, confirm }) => {
  const [states, setStates] = React.useState({ confirming: false,   stakeAmount: '', loading: false, isStaking: true });

  // const _N = useNavigate();


  function toggleConfirmation() {
    setStates({ ...states, confirming: !states.confirming });
  }


  return (
    <Wrapper className={tw.container}>
      {states.confirming && <CardLayout toggleModal={toggleConfirmation} />}
      <div className={tw.card}>
        <div className="tabs s-b  ">
          <div className="tab_cont midle radius">
            <button  onClick={() => setStates({ ...states, isStaking: true })} className={`  ${states.isStaking && 'active'} tabs_btn  fsize rounded-l-3xl`}>stake</button>

            <button onClick={() => setStates({ ...states, isStaking: false })} className={`tabs_btn   fsize rounded-r-3xl ${!states.isStaking && 'active'}`}>unstake</button>
          </div>
          <button onClick={click} className="cog_cont ">
            <img src={timesW} alt="cog" />
          </button>
        </div>

        {/* inpput section */}
        <div className="tokens mt-6  midle">
          <div className="thum_box midle">
            <img src={rib} alt="" className="thumbIcon w-9 h-9" />
            <img src={eth} alt="" className="thumbIcon w-9 h-9" />
          </div>
          <h1 className="header4 ml-2">RBN/ETH</h1>
        </div>

        <span className="s-b my-4">
          <span className="empty"></span>
          <div className="box text flex flex-col items-end">
            <h1 className="text">
              {' '}
              <span className="body1">LP Balance:</span>: 0.035
            </h1>
            <p className="body1">~ $ 10.00</p>
          </div>
        </span>

        <div className="input_cont relative  h-11 mt-2">
          <input onChange={
            (e) => updateAmount(e)
          } placeholder="0.0" type="text" name="" className="field w-full h-full bg-pri-50 p-3" id="" />
          <div className="btn_liq_ghost_auto btn_liq_ghost absolute right-2 top-2">Max</div>
        </div>

        <div className="w-full s-b mt-8">
          <h1 className="body1 ">Annual ROI at current rates</h1>
          <h1 className="text text-gray-800">10.0%</h1>
        </div>

        <div className="button w-full mt-9 s-b">
          {' '}
          <button onClick={toggleConfirmation} disabled={amount === 0 || amount === null} className="btn1 w-full txt-blu-1">
            Approve
          </button>
          <div className="w-16"></div>
          <button onClick={toggleConfirmation}  disabled={!true} className="btn1 w-full txt-blu-1">
            {states.isStaking ? 'stake' : 'unstake'}
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

// tailwind styles
const tw = {
  container: 'h-screen w-screen ctd strictFadeIn',
  card: 'card rounded-2xl bg-white  p-11'
};

// styled-c
const Wrapper = styled.main`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  background: #0007;

  .card {
    width: 500px;
  

    .loader {
      width: 150px;
      height: 150px;

      img {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .tab_cont {
      width: 200px;
      height: 39px;
      background: #dddddd49;
      button {
        text-transform: capitalize;
        color: #343b46;

        &.active {
          background: #4286f4;
          border-radius: 20px;
          color: #fff;
          transition: all 0.4s ease-in-out;
        }
      }
    }

    .field:focus{
      outline: none;
    }

    
  }
`;

export default StakeModal;
