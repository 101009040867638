import React from 'react';
import lens from '../../../assets/icons/lens.svg';
import StakeCard from './components/StakeCard';
import Navbar from '../../../components/Navbar';
import { Wrapper, tw } from './style';
import Footer from '../../../components/footer';
import { MdGridOn } from 'react-icons/md';
import { MdList } from 'react-icons/md';
// import StakeModal from './components/Modal';
import LendOption from './components/lendOptions';
// component
const Farm = ({ children, cog }) => {
  const [states, setStates] = React.useState({
    lend: false,
    stakeAmount: null,
    isSingle: true,
    cardData: {},
    onlyDeposited: true
  });
  const [cardType, setCardType] = React.useState(true);

  function showLendOption(cardData) {
    setStates({ ...states, lend: !states.lend });
  }
  function showDepositedCard() {
    setStates({ ...states, onlyDeposited: !states.onlyDeposited });
  }

 
  React.useEffect(() => {
    console.log(typeof states.stakeAmount);
  });

  const lendData = [
    {
      name: '',
      enabled: false
    },
    {
      name: '',
      enabled: false
    },
    {
      name: '',
      enabled: false
    },
    {
      name: '',
      enabled: true
    },
    {
      name: '',
      enabled: false
    },
  ];

  return (
    <>
      <Navbar />
      <Wrapper className={tw.container}>
        {/* subnav */}
        <div className={tw.subnav}>
          <div className="search_box  mx-4 h-full py-1">
            <div className={tw.inputField}>
              <input type="text" placeholder="Search Name or Symbol" className="field w-full h-full p-4" />{' '}
              <img role="button" src={lens} alt="" className="absolute right-4" />
            </div>
          </div>

          <div className="filter  p-1 midle">
            <button className="" onClick={() => setCardType(false)}>
              <MdGridOn className={`${!cardType && 'text-pri-500'} text-2xl `} />
            </button>

            <button onClick={() => setCardType(true)} className={`${cardType && 'text-pri-500'} text-3xl ml-4`}>
              <MdList />
            </button>
          </div>
        </div>

        {/*banner*/}
        <div className="banner mt-2 w-full relative ">
          <div className={tw.overlay}>
            <div className="  text-center text-white">
              <h1 className="h2">Synthetics </h1>
              <p className="mt-4 ">Buy stock from your favorite industry</p>
            </div>
          </div>
        </div>

        {/*lend data list, deposited !== active */}

        {states.lend ? (
          <LendOption toggleShowCardDetails={showLendOption} />
        ) : (
          <React.Fragment>
            <div className=" my-6 p-6 w-full px-32 s-b">
              <div className="name">
                <h1 className="header3">All Assets</h1>
              </div>
              <div className="switch flex midle">
                <p className="body2">Invested Only</p>
       <button onClick={showDepositedCard} className="toggle ml-4 ">
                  {states.onlyDeposited ? (
                    <>
                      <svg width="60" height="30" viewBox="0 0 60 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M44.582 0.416992H15.4154C7.36537 0.416992 0.832031 6.95033 0.832031 15.0003C0.832031 23.0503 7.36537 29.5837 15.4154 29.5837H44.582C52.632 29.5837 59.1654 23.0503 59.1654 15.0003C59.1654 6.95033 52.632 0.416992 44.582 0.416992ZM15.4154 23.7503C10.5737 23.7503 6.66537 19.842 6.66537 15.0003C6.66537 10.1587 10.5737 6.25033 15.4154 6.25033C20.257 6.25033 24.1654 10.1587 24.1654 15.0003C24.1654 19.842 20.257 23.7503 15.4154 23.7503Z"
                          fill="#80858C"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      <svg width="60" height="30" viewBox="0 0 60 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M44.584 0.416992H15.4173C7.36732 0.416992 0.833984 6.95033 0.833984 15.0003C0.833984 23.0503 7.36732 29.5837 15.4173 29.5837H44.584C52.634 29.5837 59.1673 23.0503 59.1673 15.0003C59.1673 6.95033 52.634 0.416992 44.584 0.416992ZM44.584 23.7503C39.7423 23.7503 35.834 19.842 35.834 15.0003C35.834 10.1587 39.7423 6.25033 44.584 6.25033C49.4257 6.25033 53.334 10.1587 53.334 15.0003C53.334 19.842 49.4257 23.7503 44.584 23.7503Z"
                          fill="#34A853"
                        />
                      </svg>
                    </>
                  )}
                </button>
              </div>
            </div>

            {states.onlyDeposited ? (
              <>
                   <div className={`${cardType ? ' px-28' : 'grid grid-cols-3 gap-7 mt-8 px-28 '} `}>
                {cardType && (
                  <div className="list-nav flex  w-full p-4 justify-around">
                    <div className="box w-40"></div>
                    <div className="box">Liquidity</div>
                    <div className="box text-center">
                     Performance
                    </div>
                    <div className="box">Share Price</div>
                    <div className="box w-40"></div>
                  </div>
                )}

                {/* deposited data */}
                {lendData.map((item, index) => (
                  <StakeCard
                    single={states.isSingle}
                    cardType={cardType}
                    click={showLendOption}
                    withName={!states.isRibbon}
                    data={item}
                    deposited
                  />
                ))}
              </div>
              </>
            ) : (
              <div className={`${cardType ? ' px-28' : 'grid grid-cols-3 gap-7 mt-8 px-28 '} `}>
                {cardType && (
                  <div className="list-nav flex  w-full p-4 justify-around">
                    <div className="box w-40"></div>
                    <div className="box">Liquidity</div>
                    <div className="box text-center">
                      Lending Rate <br /> (APY)
                    </div>
                    <div className="box">Total Borrowed</div>
                    <div className="box w-40"></div>
                  </div>
                )}
                {lendData.map((item, index) => (
                  <StakeCard
                    single={states.isSingle}
                    cardType={cardType}
                    click={showLendOption}
                    withName={!states.isRibbon}
                    data={item}
                  />
                ))}
              </div>
            )}

            <div className=" my-20  flex">
              <button className="btn1 w-48 mx-auto px-28 ">view more</button>
            </div>
          </React.Fragment>
        )}

        {/* footer */}
        <Footer />
      </Wrapper>
    </>
  );
};

export default Farm;
