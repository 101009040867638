import { createGlobalStyle } from 'styled-components';
import Airblack from '../fonts/air-black.ttf';
import Airbold from '../fonts/AirbnbCerealBold.ttf';
import Airbook from '../fonts/AirbnbCerealBook.ttf';
import AirExBold from '../fonts/AirbnbCerealExtraBold.ttf';
import AirLight from '../fonts/AirbnbCerealLight.ttf';
import Airmedium from '../fonts/AirbnbCerealMedium.ttf';

// global config for styled components
const GlobalStyle = createGlobalStyle`
  @font-face{
    font-family: "Air-black";
    font-display: swap;
    src: local('Air-black'), url(${Airblack}) format('truetype');
    font-weight:900;
  }
  
    @font-face{
    font-family: "Air-bold";
    font-display: swap;
    src: local('Air-bold'), url(${Airbold}) format('truetype');
    font-weight:700;
  } 
  
  @font-face{
    font-family: "Air-book";
    font-display: swap;
    src: local('Air-book'), url(${Airbook}) format('truetype');
    font-weight:600;
  }
  
  @font-face{
    font-family: "Air-exbold";
    font-display: swap;
    src: local('Air-exbold'), url(${AirExBold}) format('truetype');
    font-weight:800;
  }
  
    @font-face{
    font-family: "Air-light";
    font-display: swap;
    src: local('Air-light'), url(${AirLight}) format('truetype');
    font-weight:400;
  }
  
      @font-face{
    font-family: "Air-medium";
    font-display: swap;
    src: local('Air-medium'), url(${Airmedium}) format('truetype');
  }
  
  

`;

export const appThemeLight = {
  pri: {
    main: '#4285F4',
    900: '#010A18',
    800: '#041E48',
    700: '#073278',
    600: '#0A46A9',
    500: '#0D5AD9',
    400: '#5692F5',
    300: '#87B1F8',
    200: '#B7D0FB',
    100: '##E7EFFE',
    50: '##ECF3FE'
  },

  sec: {
    main: '#F44285',
    900: '#780732',
    800: '#A90A46',
    700: '#D90D5A',
    600: '#F22673',
    500: '#DC3B78',
    400: '#F55692',
    300: '#F887B1',
    200: '#FBB7D0',
    100: '#FEE7EF',
    50: '#FEECF3'
  },
  err: {
    main: '#FD0404',
    bg: '#FE9A9A',
    dk: '#CA0202'
  },

  warn: {
    main: '#FBBC05',
    dk: '#F19E13',
    bg: '#FDE49B'
  },

  suc: {
    main: '#34A853',
    dk: '#4A925D',
    bg: '#96DEA9'
  }
};

export default GlobalStyle;
