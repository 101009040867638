import React from 'react';
// import lens from '../../assets/icons/lens.svg';
// import StakeCard from './components/StakeCard';
import Navbar from '../../components/Navbar';
// import { Wrapper, tw } from './style';
// import Footer from '../../components/footer';
// import { MdGridOn } from 'react-icons/md';
// import { MdList } from 'react-icons/md';
import StakeModal from './components/Modal';
import {  Outlet } from 'react-router-dom';
// import Lend from './Lend';

// component
const Market = ({ children, cog }) => {
  const [states, setStates] = React.useState({
    stake: false,
    stakeAmount: null,
    isSingle: true,
    cardData: {}
  });
  // const [cardType, setCardType] = React.useState(true);

  function startStaking(cardData) {
    setStates({ ...states, stake: !states.stake });
  }

  function updateStakeAmount(e) {
    setStates({ ...states, stakeAmount: Number(e.target.value) });
    if (states.stakeAmount?.length === 0 && states?.stakeAmount === '') {
      setStates({ ...states, stakeAmount: null });
    }
    console.log(states.stakeAmount);
  }

  React.useEffect(() => {
    console.log(typeof states.stakeAmount);
  });

  return (
    <div className="">
      <Navbar />
      {states.stake && <StakeModal click={startStaking} updateAmount={updateStakeAmount} amount={states.stakeAmount} />}
      <Outlet />
    </div>
  );
};

export default Market;
