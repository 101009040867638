import styled from 'styled-components';
import React from 'react';

function StakeCard({ cardType, single, click, data, deposited, isRepaying }) {
  // const dropdownBodyRef = React.useRef();
  const [active, setActive] = React.useState(false);
  console.log(data);

  return (
    <>
      {!cardType ? (
        <CardLayoutOne className={`mx-auto shadow-md rounded-xl   ${active && 'active'}`}>
          <button onClick={() => setActive((p) => !p)} className="dropdown_trigger p-3  s-b w-full  mx-auto">
            <div className="tokens  midle">
              <div className="thum_box midle">{SvgRibbon}</div>
              <h1 className="header4 ml-2">RBN</h1>
            </div>
            <div className="icon"></div>
          </button>
          <div className="dropdown_body ">
            <div className="w-full items">
              <div className="item s-b">
                <p className=" body1">Liquidity</p>

                <p className="text">$249,843</p>
              </div>

              <div className="item s-b ">
                <p className=" body1">Lending Rate (APY)</p>

                <div className="box">
                  <p className="text">2.90 %</p>
                </div>
              </div>

              <div className="btn_box  mt-4 s-b ">
                <button disabled={!true} onClick={click} className="btn1 w-full p-2">
                  {deposited ? 'repay' : 'Borrow'}
                </button>
              </div>
            </div>
          </div>
        </CardLayoutOne>
      ) : (
        <CardLayoutTwo className="  shadow-md rounded-md hover:shadow-md w-full h-auto my-6 p-4 ">
          <div className="flex midle items justify-around">
            <div className="tokens  midle">
              <div className="thum_box midle">{SvgRibbon}</div>
              <h1 className="header4 ml-2">{single ? 'RBN' : 'RBN/ETH'}</h1>
            </div>

            <div className="item s-b">
              <p className="text">$249,843</p>
            </div>

        {!deposited &&    <div className="item s-b">
              <p className="text">40%</p>
            </div>}

            <div className="item s-b">
              <div className="box">
                <p className="text">0.004</p>
                <p className="body1">~ $ 0.2</p>
              </div>
            </div>

            <div className="btn_box  s-b ">
              <button onClick={click} className="btn1 w-40 p-2">
                {deposited ? 'repay' : 'Borrow'}
              </button>
            </div>
          </div>
        </CardLayoutTwo>
      )}
    </>
  );
}

const SvgRibbon = (
  <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle r="25" transform="matrix(1 0 0.00750483 0.999972 25.1876 24.9993)" fill="#4285F4" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.5561 28.6135H19.715L19.7695 35.8878H20.2833C20.8425 35.8878 21.2991 36.341 21.3033 36.9002C21.3075 37.4593 20.8576 37.9126 20.2985 37.9126H19.7847L19.7861 38.0993C19.7967 39.5123 18.8554 40.7033 17.5618 41.0682C17.3803 41.3266 17.0799 41.4952 16.7389 41.4952C16.4014 41.4952 16.1012 41.33 15.9153 41.0761C14.6018 40.7207 13.6286 39.5226 13.6179 38.0993L13.6165 37.9126H13.1334C12.5742 37.9126 12.1175 37.4593 12.1133 36.9002C12.1091 36.341 12.559 35.8878 13.1182 35.8878H13.6013L13.4836 20.197C13.4354 13.7741 18.6032 8.56738 25.0262 8.56738H28.1159C34.0505 8.56738 37.7715 12.4925 37.8178 18.6606C37.8515 23.1464 35.821 26.5575 31.8125 27.9126L36.3331 36.7585C37.3704 38.7883 35.9129 41.1833 33.6401 41.1833C32.4692 41.1833 31.3964 40.5134 30.879 39.4592L25.5561 28.6135ZM19.6048 13.9411L23.9064 26.2505L19.6048 28.6135C20.8909 27.9419 22.4022 27.1272 23.9064 26.2505C27.6942 24.0427 31.4368 21.441 31.424 19.7353L31.4079 17.5858C31.39 15.2027 29.9319 13.9411 27.5488 13.9411H19.6048Z"
      fill="#F7FAFF"
    />
  </svg>
);

// tailwind styles
// const tw = {
//   container: 'p-4 py-5 bg-white rounded-xl fadeIn relative hover:shadow-lg shadow-sm'
// };

// styled-c
const CardLayoutTwo = styled.div``;

const CardLayoutOne = styled.div`
  width: 83%;
  margin-top: 10px;
  .dropdown_trigger {
    height: 60px;
    background: #fff;
    box-shadow: 0 1px 1px 0 #b3b6ba1a, 0 1px 1px 0 #b3b6ba1a, 0 1px 1px 0 #b3b6ba1a;
  }

  .dropdown_body {
    /* background: #fff; */
    margin-top: 1px;
    visibility: visible;
    height: auto;
    padding: 16px;
    opacity: 1;
    transition: opacity 0.4s linear;

    .items {
      opacity: 1;
      transition: opacity 0.2s linear;
    }

    .item {
      margin-top: 25px;
      margin-bottom: 35px;
    }
  }
`;

export default StakeCard;
