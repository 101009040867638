import styled from 'styled-components';
import React from 'react';
import book from '../../../../assets/images/market/books.png';
import avatar from '../../../../assets/images/market/pub.png';

const ProductCard = ({ withName, click }) => {
  // const [states, setStates] = React.useState(null);



  return (
    <Wrapper className={tw.container} onClick={click}>
      <div className="media ">
        <img src={book} alt="" className="fsize" />
      </div>

      <h1 className="nameTxt  mt-3">Raw Covid- 19 Research Health Data</h1>
      <p className="text-[#F44285] font-abk mt-2">10.00 GHF</p>
      <div className="full midle mt-3">
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0 1.005L3.75 4.76L5.75 2.76L9.295 6.745L10 6.04L5.75 1.26L3.75 3.26L0.75 0.254995L0 1.005ZM0.75 3.255L3.75 6.26L5.75 4.26L10 9.04L9.295 9.745L5.75 5.76L3.75 7.76L0 4.005L0.75 3.255Z"
            fill="#80858C"
          />
        </svg>
        <p className=" ml-3 font-abk text-sm text-typo-1">$ 1200.00</p>
      </div>

     {withName && <div className="name_box full midle mt-3">
        <img src={avatar} alt="" className="" />
        <p className="b3 ml-3">Dr Bravestone</p>
      </div>}
    </Wrapper>
  );
};

// tailwind styles
const tw = {
  container: 'p-4 py-5 bg-white rounded-xl fadeIn relative hover:shadow-lg shadow-sm'
};

// styled-c
const Wrapper = styled.button`
text-align: left;
  width: 100%;
  height: auto;

  .media {
    width: 100%;
    height: 200px;
  }
`;

export default ProductCard;
