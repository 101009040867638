import styled from 'styled-components';
import React from 'react';
import cake from '../../../../../../assets/icons/CAKE - PancakeSwap Token.png';
import times from '../../../../../../assets/icons/times.svg';
import timesW from '../../../../../../assets/icons/timesW.svg';
import logo from '../../../../../../assets/icons/Group 2782.svg';
// import { Portal } from 'react-portal';
// import { Oval } from 'react-loader-spinner';
// import { css } from '@emotion/react';
import MoonLoader from 'react-spinners/MoonLoader';
// import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

// const AFFIRM = 'AFFIRM';

const CardLayout = ({ toggleModal, status, data, confirm }) => {
  const [states, setStates] = React.useState({ confirming: true, loading: false });

  function IsMinting(progress) {
    setStates({ ...states, confirming: false, loading: true });
    setTimeout(() => {
      setStates({ ...states, confirming: false, loading: false });
    }, 2000);
  }


  // function disableBodyScroll(){
  //   let bodyEl = document.body
  //   bodyEl.style.overflow = "hidden";
  // }


  return (
    <Wrapper className={tw.container}>
      {states.confirming ? (
        <Confirmation toggleModal={toggleModal} mint={IsMinting}/>
      ) : states.loading ? (
        <Loader toggleModal={toggleModal} />
      ) : (
        <Success toggleModal={toggleModal} />
      )}
    </Wrapper>
  );
};

function Confirmation({ toggleModal, mint }) {
  return (
    <div className="card bg-white rounded h-48 w-48 radius fadeIn">
      <div className="blue bg-[#073278] w-full h-1/2 rounded-t-xl p-9 flex flex-col justify-between">
        <div className="action s-b p-1">
          <p className="text-white capitalize">Withdrawing</p>
          <button className="times" onClick={() => toggleModal(false)}>
            <img src={times} alt="" />
          </button>
        </div>

        <div className="token-name flex items-end text-white ">
          <h1 className=" text-5xl">243</h1>
          <p className="uppercase text-xl ml-2 text-gray-300">cake</p>
          <img src={cake} alt="" className="h-7 w-7 ml-2 mb-1" />
        </div>
      </div>
      <div className="white bg-white w-full h-1/2 rounded-b-xl ctd p-11">
        <button onClick={mint} className="btn1 w-full">Confirm Withdrawing</button>
      </div>
    </div>
  );
}

function Loader({ toggleModal }) {
  return (
    <div className="card bg-white rounded h-48 w-48 radius p-9  flex flex-col justify-between fadeIn">
      <div className="action s-b p-1">
        <p className="text-white capitalize"></p>
        <button className="times" onClick={() => toggleModal(false)}>
          <img src={timesW} alt="" />
        </button>
      </div>

      <div className="w-full h-auto  flex flex-col items-center text-[#1A222F]">
        <div className="loader  ctd relative ">
          <img src={logo} alt="" className="w-11 h-11 absolute" />
          <MoonLoader color="#4286f4" size={80} />
        </div>
        <p className="wait font-abk mt-11 text-[#1A222F] text-xl">Waiting for Confimation</p>
        <p className="wait font-abld text-lg  my-1">Minting 3.745 RBN to 0.7435 AFYA</p>
        <p className="conf text-[#676C74] text-sm">Confirm this transaction in your Wallet</p>
      </div>
    </div>
  );
}

function Success({ toggleModal }) {
  return (
    <div className="card bg-white rounded h-48 w-48 radius p-9  flex flex-col justify-between fadeIn">
      <div className="action s-b p-1">
        <p className="text-white capitalize"></p>
        <button className="times" onClick={() => toggleModal(false)}>
          <img src={timesW} alt="" />
        </button>
      </div>

      <div className="w-full h-auto  flex flex-col items-center text-[#1A222F]">
        <p className="h3  mt-11">Transaction Completed</p>
        <p className="wait mt-1 text-[#34A853]">View Transaction</p>
        <button onClick={() => toggleModal(false)} className="btn1 text-[#676C74] w-full mt-4">Close</button>
      </div>
    </div>
  );
}

// tailwind styles
const tw = {
  container: 'h-screen w-screen ctd strictFadeIn'
};

// styled-c
const Wrapper = styled.main`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  background: #0007;

  .card {
    width: 500px;
    height: 400px;

    .loader {
      width: 150px;
      height: 150px;

      img {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
`;

export default CardLayout;
