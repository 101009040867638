import styled from 'styled-components';
import banner1 from '../../assets/images/market/Mataura.png';
// tailwind styles
export const tw = {
    container: 'pb-0',
    subnav: 'bg-white h-18 w-full px-8  s-b',
    button: 'font-abk filterbtn p-2 s-b w-24 px-4 rounded-lg border-2 border-[#B7D0FB] bg-white',
    overlay: 'ovelay bg-[#0000003a] w-full h-full absolute top-0 left-0  ctd',
    inputField: 'input_field midle relative bg-[#4286f42a] rounded'
  };
  
  // styled-c
  export const Wrapper = styled.main`
  margin-top:70px;
  
  
    .tab {
      width: 20%;
    }
    .search_box {
      width: 90%;
      .input_field {
        height: 46px;
        .field {
          background: transparent;
          &:focus{
            outline: none;
          }
        }
      }
    }
    .filter {
      width: 10%;
    }
  
    .banner {
      height: 200px;
      background-image: url(${banner1});
      background-repeat: no-repeat;
      background-size: cover;
      background-color: white;

      .cartIcon{
        right: 30px;
        bottom: -10px;
      }
    }
  `;
  