import styled from 'styled-components';
import React from 'react';
// import cake from '../../../../../assets/icons/CAKE - PancakeSwap Token.png';
// import times from '../../../../../assets/icons/times.svg';
import timesW from '../../../../../assets/icons/times.svg';
// import logo from '../../../../../assets/icons/Group 2782.svg';
import book from '../../../../../assets/images/market/book2.png';
import books from '../../../../../assets/images/market/books.png';
import { useNavigate } from 'react-router-dom';
import CardLayout from '../../../../../components/modals/statusLoader';
// import {useOnClickOutside} from '../../../../../hooks/useOutsideClick'
// import { Portal } from 'react-portal';
// import { Oval } from 'react-loader-spinner';
// import { css } from '@emotion/react';

const PurchaseModal = ({ toggleModal, status, data, confirm }) => {
  const [states, setStates] = React.useState({ confirming: false, loading: false });
  const _N = useNavigate();
  // const modalRef = React.useRef()

  // function IsMinting(progress) {
  //   setStates({ ...states, confirming: false, loading: true });
  //   setTimeout(() => {
  //     setStates({ ...states, confirming: false, loading: false });
  //   }, 2000);
  // }


  function toggleConfirmation(){
    setStates({ ...states, confirming: !states.confirming});

  }
//   useOnClickOutside(modalRef, toggleModal)

//    function disableBodyScroll(){
//     let bodyEl = document.body
//     if(states.confirming){
//         bodyEl.style.overflow = "hidden";

//     }else{
//         bodyEl.style.overflow = "scroll";

//     }
//   }

  


  return (
    <Wrapper className={tw.container}>
        {states.confirming && <CardLayout toggleModal={toggleConfirmation} />}
      <div className="scrollable overflow-y-auto  ">
        <div className={tw.card}>
          <div className="top_blue s-b items-center bg-[#073278] h-14 p-6 w-full rounded-t-2xl">
            <p className="font-am text-white ">Purchase Review</p>
            <button onClick={toggleModal} className="btn_cancle">
              <img src={timesW} alt="" />
            </button>
          </div>

          <div className="card_body overflow-y-scroll w-full h-full rounded-b-2xl  p-6">
            {/* item box */}
            <div className="item_box  py-3 border-b border-dashed">
              <h1 className="header4">Item</h1>
            </div>

            <div className="item_box  py-1 border-b-2 border-dashed">
              {/* product box */}
              <div className="prod_box my-3 w-full flex items-start">
                <img src={book} alt="" className="product_thumb " />
                <div className="text_box flex justify-between items-start ">
                  <h1 className="header4 ml-3">Raw Covid- 19 Research Health Data</h1>
                  <p className="text_sec_sm">10.00 GHF</p>
                </div>
              </div>

              <div className="prod_box my-3 w-full flex items-start">
                <img src={books} alt="" className="product_thumb " />
                <div className="text_box flex justify-between items-start ">
                  <h1 className="header4 ml-3">Raw Covid- 19 Research Health Data</h1>
                  <p className="text_sec_sm">10.00 GHF</p>
                </div>
              </div>
            </div>

            <div className="item_box  py-3 border-b border-dashed">
              <div className="total_box py-3 flex justify-between items-start">
                <h1 className="header4 capitalize">total</h1>
                <div className="amount_box">
                  <p className="text_sec_sm">10.00 GHF</p>
                  <div className="full midle mt-3">
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0 1.005L3.75 4.76L5.75 2.76L9.295 6.745L10 6.04L5.75 1.26L3.75 3.26L0.75 0.254995L0 1.005ZM0.75 3.255L3.75 6.26L5.75 4.26L10 9.04L9.295 9.745L5.75 5.76L3.75 7.76L0 4.005L0.75 3.255Z"
                        fill="#80858C"
                      />
                    </svg>
                    <p className=" ml-3  font-abk text-sm text-typo-1">$ 1200.00</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="item_box  py-3 border-b border-dashed">
              <div className="total_box py-3 flex justify-between items-start">
                <h1 className="header4 capitalize">GHF in Wallet</h1>
                {true ? (
                  <button disabled={!true} className="btn1 p-2">
                    connect to wallet
                  </button>
                ) : (
                  <div className="amount_box ">
                    <p className={!true ? 'text_sec_sm' : 'text_green'}>10.00 GHF</p>
                    <div className="full midle mt-3">
                      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M0 1.005L3.75 4.76L5.75 2.76L9.295 6.745L10 6.04L5.75 1.26L3.75 3.26L0.75 0.254995L0 1.005ZM0.75 3.255L3.75 6.26L5.75 4.26L10 9.04L9.295 9.745L5.75 5.76L3.75 7.76L0 4.005L0.75 3.255Z"
                          fill="#80858C"
                        />
                      </svg>
                      <p className=" ml-3  font-abk text-sm text-typo-1">$ 1200.00</p>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="item_box  py-3 border-b border-dashed">
              {true && (
                <div className="error_box bg-[#FD04042a] rounded-xl w-full p-3 my-3 midle border border-[#CA0202]">
                  <span className="icon">
                    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M41 20.5C41 9.17816 31.8218 0 20.5 0C9.17816 0 0 9.17816 0 20.5C0 31.8218 9.17816 41 20.5 41C31.8218 41 41 31.8218 41 20.5Z"
                        fill="white"
                      />
                      <path
                        d="M17.9844 11H22.0156V23H17.9844V11ZM18.5469 28.4375C18.1719 28.0312 17.9844 27.5469 17.9844 26.9844C17.9844 26.4219 18.1719 25.9531 18.5469 25.5781C18.9531 25.2031 19.4375 25.0156 20 25.0156C20.5625 25.0156 21.0312 25.2031 21.4062 25.5781C21.8125 25.9531 22.0156 26.4219 22.0156 26.9844C22.0156 27.5469 21.8125 28.0312 21.4062 28.4375C21.0312 28.8125 20.5625 29 20 29C19.4375 29 18.9531 28.8125 18.5469 28.4375Z"
                        fill="#E40046"
                      />
                    </svg>
                  </span>
                  <p className="font-am error_text text-[#FD0404] ml-4 text-sm">Not Enough GHF to purchase this data</p>
                </div>
              )}
            </div>

            <div className="item_box p-3  py-3 border-b border-dashed">
              <button onClick={toggleConfirmation} disabled={!true} className="btn1 w-full">
                checkout
              </button>

              <button onClick={() => _N('/swap')} className="btn_ghost w-full mt-4">
                Get GHF
              </button>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

// tailwind styles
const tw = {
  container: 'h-screen w-screen ctd strictFadeIn',
  card: 'card rounded-2xl bg-white '
};

// styled-c
const Wrapper = styled.main`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  background: #0007;
  /* overflow-y: scroll; */
  /* padding:50px 0;
  height: auto; */

  .scrollable {
    overflow-y: scroll;
    width: 500px;
    min-height: 400px;
    max-height: 600px;
  }

  .card {
    width: 500px;
    min-height: 400px;

    .item_box {
      .prod_box {
        .product_thumb {
          width: 100px;
          height: 100px;
          border-radius: 20px;
        }

        h1 {
          width: 45%;
        }
      }
    }

    .error_box {
      width: 80%;
      margin: 0 auto;
    }
  }
`;

export default PurchaseModal;
