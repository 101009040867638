import styled from 'styled-components';
import React from 'react';
import caret from '../../../../../assets/icons/carret.svg';
import StatusLoader from './Modal';

const LendOptions = ({ isRibbon, toggleShowCardDetails, mode }) => {
  const [states, setStates] = React.useState({ isBorrowing: true, loading: false });
  const [step, setStep] = React.useState(0);

  function toggleModal() {
    setStates({ ...states, isBorrowing: !states.isBorrowing });
  }

  function steps(step) {
    switch (step) {
      case 0:
        return <BorrowSelect setStep={setStep} mode={mode} />;
      case 1:
        return <Summary toggleModal={toggleModal} mode={mode} />;

      default:
        break;
    }
  }

  return (
    <React.Fragment>
      {states.isBorrowing && <StatusLoader context="borrowing" toggleModal={toggleModal} />}

      <Wrapper className={tw.container}>
        {/* sub nav */}
        <div className="nav mx-auto w-10/12 flex-start">
          <button onClick={() => toggleShowCardDetails()} className="midle">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M20 8.75H4.7875L11.775 1.7625L10 0L0 10L10 20L11.7625 18.2375L4.7875 11.25H20V8.75Z"
                fill="#4D545D"
              />
            </svg>
            <p className="ml-3 capitalize text-xl">go back</p>
          </button>
        </div>

        {/* CARDS */}
        <div className={tw.card}>
          {/* card 1 */}
          {!mode && (
            <div className="user_details bg-[#fafcff] w-1/2 rounded-xl  h-full p-4">
              <div className="main_list mt-8 my-6 items-center grid grid-cols-4 gap-4 p-6">
                <div className="item s-b  flex flex-col w-full h-full">
                  <div className="row_one s-b w-full">
                    <h1 className="body4">Liquidity</h1>
                    <h1 className="text">2.90%</h1>
                  </div>

                  <div className="row_two s-b w-full">
                    <h1 className="body4">Liquidity</h1>
                    <h1 className="text">2.90%</h1>
                  </div>
                </div>

                <div className="item s-b  flex flex-col w-full h-full">
                  <div className="row_one s-b w-full">
                    <h1 className="body4">Walllet Balance</h1>
                    <h1 className="text">2.90%</h1>
                  </div>

                  <div className="row_two s-b w-full">
                    <h1 className="body4">300.00 RBN</h1>
                    <h1 className="text">2.90%</h1>
                  </div>
                </div>

                <div className="item s-b  flex flex-col w-full h-full">
                  <div className="row_one s-b w-full">
                    <h1 className="body4">Liquidity</h1>
                    <h1 className="text">2.90%</h1>
                  </div>

                  <div className="row_two s-b w-full">
                    <h1 className="body4">Liquidity</h1>
                    <h1 className="text">2.90%</h1>
                  </div>
                </div>

                <div className="item s-b  flex flex-col w-full h-full">
                  <div className="row_one s-b w-full">
                    <h1 className="body4">Liquidity</h1>
                    <h1 className="text">2.90%</h1>
                  </div>

                  <div className="row_two s-b w-full">
                    <h1 className="body4">Borrow APY</h1>
                    <h1 className="text">2.90%</h1>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* card2 */}

          {mode ? (
            <div className={tw.details}>
              <div className="from  px-9 p-2">
                <div className="flex justify-between mt-3 text">Repay loan</div>
                {mode ? (
                  <div className="flex mt-3 items-center  ctd">
                    <h1 className="body1">Current Balance: </h1>
                    <p className="text ml-4">300.00 RBN</p>
                  </div>
                ) : (
                  <p className="body1 mt-2">Lend out your asset and earn</p>
                )}

                <span className="s-b mt-12">
                  <span className="empty"> Enter Lend Amount:</span>
                  {!mode && (
                    <h1 className="empty text">
                      <span className="body1">Balance</span>: 4.3434 RBN
                    </h1>
                  )}
                </span>

                <div className="input_cont relative  h-11 mt-2">
                  <input placeholder="0.0" type="text" name="" className="field w-full h-full bg-pri-50 p-3" id="" />

                  <div className="btn_liq_ghost_auto btn_liq_ghost max_btn absolute top-2">Max</div>

                  <div className=" absolute right-0 top-0  ">
                    <Dropdown className="midle p-1 h-11 px-4 bg-sec-100 relative">
                      {SvgRibbonSm}
                      <h1 className="select text ml-1">RBN</h1>
                      <img src={caret} alt="" className="ml-3" />

                      <div className="tokens absolute "></div>
                    </Dropdown>
                  </div>
                </div>
                <p className="body1 mt-11">YUse collateral to repay loan</p>
              </div>

        
             <div className="p-6">

                <button disabled={!true} onClick={toggleModal} className="btn1 w-full txt-blu-1">
                  Repay 300 RBN
                </button>
             </div>

            
            </div>
          ) : (
            <div className={tw.details}>{steps(step)}</div>
          )}
        </div>
      </Wrapper>
    </React.Fragment>
  );
};

// ______step one. amount to borrow________@devMarines
const BorrowSelect = ({ setStep, mode }) => {
  return (
    <>
      <div className="from px-9 p-2">
        <div className="flex justify-between mt-3 header4">Borrow</div>
        <p className="body1 mt-2">Loan assets and repay anytime</p>

        <>
          {' '}
          <span className="s-b mt-12">
            <span className="empty"> Enter Lend Amount:</span>
            <h1 className="empty text"> </h1>
          </span>
          <div className="input_cont relative  h-11 mt-2">
            <input placeholder="0.0" type="text" name="" className="field w-full h-full bg-pri-50 p-3" id="" />

            <div className="btn_liq_ghost_auto btn_liq_ghost max_btn absolute top-2">Max</div>

            <div className=" absolute right-0 top-0  ">
              <Dropdown className="midle p-1 h-11 px-4 bg-sec-100 relative">
                {SvgRibbonSm}
                <h1 className="select text ml-1">RBN</h1>
                <img src={caret} alt="" className="ml-3" />

                <div className="tokens absolute "></div>
              </Dropdown>
            </div>
          </div>
        </>

        {/* COLLATERAL */}
        <div className="collateral-type text-center mt-11">
          <p className="body1 text-left">Choose Collateral type:</p>

          <div className="radio_box flex w-full justify-around mt-4">
            <div className="flex midle">
              <input type="radio" name="" id="" autoFocus={true} />
              <p className="body2 ml-4 capitalize">token</p>
            </div>

            <div className="flex midle">
              <input type="radio" name="" id="" />
              <p className="body2 ml-4 capitalize">Published Data (NFT)</p>
            </div>

            <div className="flex midle">
              <input type="radio" name="" id="" />
              <p className="body2 ml-4 capitalize">both</p>
            </div>
          </div>

          {/* {!true && (
            <div className="card-types grid grid-cols-3 gap-4 w-full mt-6 p-1">
              <div className="collateral-card rounded border border-pri-200 p-4 w-full h-28  bg-pri-200  text-white  flex justify-center items-end">
                <p className="text-sm capitalize">token</p>
              </div>

              <div className="collateral-card rounded border border-pri-200 p-4 w-full h-28 flex justify-center items-end">
                <p className="text-sm capitalize">
                  Published Data <br /> (NFT)
                </p>
              </div>

              <div className="collateral-card rounded border border-pri-200 p-4 w-full h-28 flex justify-center items-end">
                <p className="text-sm capitalize ">Both Crypto and NFT</p>
              </div>
            </div>
          )} */}
        </div>
        {/* COLLATERAL */}

        {true && (
          <>
            {' '}
            <span className="s-b mt-12 mb-6">
              <span className="empty"> Enter collateral Amount:</span>
              <h1 className="empty body justify-end items-end flex flex-col">
                wallet balance 4.3434 RBN
                <p className="body1">~ $ 10.00</p>
              </h1>
            </span>
            <div className="input_cont relative  h-11 mt-2">
              <input placeholder="0.0" type="text" name="" className="field w-full h-full bg-pri-50 p-3" id="" />

              <div className="btn_liq_ghost_auto btn_liq_ghost max_btn absolute top-2">Max</div>

              <div className=" absolute right-0 top-0  ">
                <Dropdown className="midle p-1 h-11 px-4 bg-sec-100 relative">
                  {SvgRibbonSm}
                  <h1 className="select text ml-1">RBN</h1>
                  <img src={caret} alt="" className="ml-3" />

                  <div className="tokens absolute "></div>
                </Dropdown>
              </div>
            </div>
          </>
        )}

        {true && (
          <div className="ntf_box my-6 w-full h-auto p-2 mt-5 ctd ">
            {/* EMPTY NFT */}
            {true ? (
              <div className="text-center">
                <h1 className="text"> You do not have any published data</h1>

                <p className="text-sec-main mt-4">Click here to publish</p>
              </div>
            ) : (
              // listed nft
              <div className="w-full nft_list">
                <div className="w-full h-20 my-4  flex nft_item border border-pri-100 rounded-lg">
                  <div className="thumbnail full  bg-gray-400 rounded-l-lg"></div>
                  <div className="detail_box s-b w-full  p-2 rounded-r-lg">
                    <div className="name_box text-gray-800">
                      <h1>
                        {' '}
                        Pineaple bark effects on <br /> covid-19
                      </h1>
                    </div>
                    <div className="price_box">2.34 RBN</div>
                  </div>
                </div>

                <div className="w-full h-20 my-4  flex nft_item border border-pri-100 rounded-lg">
                  <div className="thumbnail full  bg-gray-400 rounded-l-lg"></div>
                  <div className="detail_box s-b w-full  p-2 rounded-r-lg">
                    <div className="name_box text-gray-800">
                      <h1>
                        {' '}
                        Pineaple bark effects on <br /> covid-19
                      </h1>
                    </div>
                    <div className="price_box">2.34 RBN</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div onClick={() => setStep(1)} className="button_box  s-b p-6  mt-6">
        {' '}
        <button disabled={!true} className="btn1 w-full txt-blu-1">
          continue
        </button>
      </div>
    </>
  );
};

// !// ______step two. summary________@devMarines
const Summary = ({ toggleModal }) => {
  return (
    <>
      <div className="flex justify-between mt-3 header4">Borrow</div>
      <p className="body1 mt-2">Loan assets and repay anytime</p>

      {/* DURATION */}
      <div className="card-types grid grid-cols-3 gap-4 w-full mt-6 p-1">
        <div className="collateral-card hover:border-2 hover:border-pri-200  rounded border border-pri-100 p-4 w-full h-28  flex justify-center items-center">
          <p className="text-sm capitalize">3 month</p>
        </div>

        <div className="collateral-card hover:border-2 hover:border-pri-200 rounded border border-pri-100 p-4 w-full h-28 flex justify-center items-center">
          <p className="text-sm capitalize">1 year</p>
        </div>

        <div className="collateral-card hover:border-2 hover:border-pri-200 rounded border border-pri-100 p-4 w-full h-28 flex justify-center items-center">
          <p className="text-sm capitalize ">6 month</p>
        </div>
      </div>
      {/* DURATION */}

      {/* SUMMARY */}
      <div className="sumary rounded bg-[#F8FAFE] w-full h-auto p-8 my-6">
        <div className="row s-b">
          <p className="name body capitalize">Borrow Amount</p>
          <p className="value text capitalize">300.00 RBN</p>
        </div>

        <div className="row s-b">
          <p className="name body capitalize">Borrow APY</p>
          <p className="value text capitalize">2.37%</p>
        </div>

        <div className="row s-b">
          <p className="name body capitalize">Collateral Type</p>
          <p className="value text capitalize">Token</p>
        </div>

        <div className="row s-b">
          <p className="name body capitalize">Collateral Amount</p>
          <p className="value text capitalize">300.00 RBN</p>
        </div>
      </div>
      {/* SUMMARY */}

      <div className="button_box mt-11 s-b p-6  ">
        {' '}
        <button onClick={toggleModal} disabled={!true} className="btn1 w-full txt-blu-1">
          Supply
        </button>
        <div className="w-16"></div>
        <button disabled={true} className="btn1 w-full txt-blu-1">
          Add Liquidity
        </button>
      </div>
    </>
  );
};

const SvgRibbonSm = (
  <svg width="25" height="25" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle r="25" transform="matrix(1 0 0.00750483 0.999972 25.1876 24.9993)" fill="#4285F4" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.5561 28.6135H19.715L19.7695 35.8878H20.2833C20.8425 35.8878 21.2991 36.341 21.3033 36.9002C21.3075 37.4593 20.8576 37.9126 20.2985 37.9126H19.7847L19.7861 38.0993C19.7967 39.5123 18.8554 40.7033 17.5618 41.0682C17.3803 41.3266 17.0799 41.4952 16.7389 41.4952C16.4014 41.4952 16.1012 41.33 15.9153 41.0761C14.6018 40.7207 13.6286 39.5226 13.6179 38.0993L13.6165 37.9126H13.1334C12.5742 37.9126 12.1175 37.4593 12.1133 36.9002C12.1091 36.341 12.559 35.8878 13.1182 35.8878H13.6013L13.4836 20.197C13.4354 13.7741 18.6032 8.56738 25.0262 8.56738H28.1159C34.0505 8.56738 37.7715 12.4925 37.8178 18.6606C37.8515 23.1464 35.821 26.5575 31.8125 27.9126L36.3331 36.7585C37.3704 38.7883 35.9129 41.1833 33.6401 41.1833C32.4692 41.1833 31.3964 40.5134 30.879 39.4592L25.5561 28.6135ZM19.6048 13.9411L23.9064 26.2505L19.6048 28.6135C20.8909 27.9419 22.4022 27.1272 23.9064 26.2505C27.6942 24.0427 31.4368 21.441 31.424 19.7353L31.4079 17.5858C31.39 15.2027 29.9319 13.9411 27.5488 13.9411H19.6048Z"
      fill="#F7FAFF"
    />
  </svg>
);

// tailwind styles
const tw = {
  container: 'bg-white relative rounded-xl  relative  p-6 mb-20 ',
  card: 'w-10/12  mx-auto p-4 flex flex-col items-center justify-center strictFadeIn rounded-xl mt-11',
  details: 'w-1/2 border border-pri-300 rounded-xl h-full p-4 py-11 ml-2 strictFadeIn lendcard',
  media: 'img_box strictFadeIn rounded-2xl ',
  publisher: 'midle',
  action: 'midle my-6',
  address: ' w-10/12 my-11 p-6 mx-auto s-b',
  copy: 'w-60 '
};

// styled-c
const Wrapper = styled.div`
  .img_box {
    width: 450px;
    height: 450px;
  }
  .thumbnail {
    width: 30%;
  }

  .cto_btn {
    margin-left: 10%;
  }

  .user_details {
    height: auto;
    width: 100%;
  }
  .lendcard {
    height: auto;
  }

  .item {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .button_box {
    /* margin-top: 10%; */
  }

  .max_btn {
    left: 40%;
  }

  .collateral-card {
  }
`;

const Dropdown = styled.button`
  &:hover {
    .tokens {
      background-color: #fff;
      width: 140px;
      height: auto;
      top: 38px;
      left: 0;
      display: block;
    }
  }
  .tokens {
    background-color: #fff;
    width: 140px;
    height: 254px;
    top: 38px;
    left: 0;
    display: none;
  }
`;
export default LendOptions;
