import styled from 'styled-components';
import React from 'react';
import cogIcon from '../../../assets/icons/cog.svg';
import SwapCard from '../components/SwapCard';
import Mint from './mint';

const CardLayout = ({ children, cog, swap, mint }) => {
  const [swapping, setSwapping] = React.useState(true);

  return (
    <Wrapper className={tw.container}>
      <div className="main_card fsize bg-white  radius p-9 ">
        <div className="tabs s-b  ">
          <div className="tab_cont midle radius">
            <button
              onClick={() => setSwapping(true)}
              className={`  ${swapping && 'active'} tabs_btn  fsize rounded-l-3xl`}
            >
              swap
            </button>
            
            <button
              onClick={() => setSwapping(false)}
              className={`tabs_btn   fsize rounded-r-3xl ${!swapping && 'active'}`}
            >
              mint
            </button>
          </div>
          <button className="cog_cont ">
            <img src={cogIcon} alt="cog" />
          </button>
        </div>

        <div className="fsize  mt-2">{!swapping ? <Mint /> : <SwapCard />}</div>

        {!swapping && (
          <div className="price absolute p-2 rounded strictFadeIn">
            <div className="inner_block p-2">
              <h1 className="capitalize">Exchane Rate</h1>
              <h1 className="capitalize text">1 CAKE = 0.004 AFYA</h1>
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

// tailwind styles
const tw = {
  container: 'bg-white relative radius fadeIn relative'
};

// styled-c
const Wrapper = styled.main`

  width: 600px;
  /* height: 450px; */
  height: auto;
  padding: 1px;
  background-image: linear-gradient(to bottom, #4285f4, #d90d5a);

  .price {
    background: #fafafa;
    top: 20px;
    left: -220px;

    .inner_block {
      border-left: 3px solid #d90d5a;
    }
  }

  .tab_cont {
    width: 200px;
    height: 39px;
    background: #dddddd49;
    button {
      text-transform: capitalize;
      color: #343b46;

      &.active {
        background: #4286f4;
        border-radius: 20px;
        color: #fff;
      }
    }
  }
`;

export default CardLayout;
