import styled from 'styled-components';
import React from 'react';

import rib from '../../../../assets/icons/liquidity/rib.svg';
import eth from '../../../../assets/icons/liquidity/eth.svg';


function StakeCard({ cardType, single, click, data }) {
  // const dropdownBodyRef = React.useRef();
  const [active, setActive] = React.useState(false);
  console.log(data);

  return (
    <>
      {!cardType ? (
        <CardLayoutOne className={`mx-auto shadow-md rounded-xl   ${active && 'active'}`}>
          <button onClick={() => setActive((p) => !p)} className="dropdown_trigger p-3  s-b w-full  mx-auto">
            <div className="tokens  midle">
              <div className="thum_box midle">
                <img src={rib} alt="" className="thumbIcon w-9 h-9" />
                {single && <img src={eth} alt="" className="thumbIcon w-9 h-9" />}
              </div>
              <h1 className="header4 ml-2">{single ? 'RBN/ETH' : 'RBN'}</h1>
            </div>
            <div className="icon"></div>
          </button>
          <div className="dropdown_body ">
            <div className="w-full items">
              <div className="item s-b">
                <p className=" body1">Liquidity</p>

                <p className="text">$249,843</p>
              </div>

              <div className="item s-b">
                <p className=" body1">APR</p>

                <p className="text">40%</p>
              </div>

              <div className="item s-b">
                <p className=" body1">LP Staked</p>

                <div className="box">
                  <p className="text">0.004</p>
                  <p className="body1">~ $ 0.2</p>
                </div>
              </div>

              <div className="item s-b">
                <p className=" body1">Your Reward</p>

                <p className="text">10 RBN/Day</p>
              </div>

              {data.enabled ? (
                <div className="w-full midle  s-b p-2 space-x-4">
                  <button className="harvest rounded-xl p-3 px-8 w-full  bg-[#FEECF34a] text-sec-500">Harvest  0.0003RBN</button>
                  <div className="operation s-b w-40 ">
                    <button className="minus border border-pri-main text-pri-main rounded-xl text-xl px-4  p-2">-</button>
                    <button className="plus border border-pri-main text-pri-main  rounded-xl text-xl px-4 p-2">+</button>
                  </div> 
                </div>
              ) : (
                <div className="btn_box  mt-4 s-b ">
                  <button disabled={!true} onClick={click} className="btn1 w-full p-2">
                    Enable
                  </button>
                </div>
              )}
            </div>
          </div>
        </CardLayoutOne>
      ) : (
        <CardLayoutTwo className="  shadow-md rounded-md hover:shadow-md w-full h-auto my-6 p-4 ">
          <div className="flex midle items justify-around">
            <div className="tokens  midle">
              <div className="thum_box midle">
                <img src={rib} alt="" className="thumbIcon w-9 h-9" />
                {single && <img src={eth} alt="" className="thumbIcon w-9 h-9" />}
              </div>
              <h1 className="header4 ml-2">{single ? 'RBN/ETH' : 'RBN'}</h1>
            </div>

            <div className="item s-b">
              <p className="text">$249,843</p>
            </div>

            <div className="item s-b">
              <p className="text">40%</p>
            </div>

            <div className="item s-b">
              <div className="box">
                <p className="text">0.004</p>
                <p className="body1">~ $ 0.2</p>
              </div>
            </div>

            <div className="item s-b">
              <p className="text">10 RBN/Day</p>
            </div>

            <div className="btn_box  s-b ">
              <button onClick={click} className="btn1 w-40 p-2">
                Enable
              </button>
            </div>
          </div>
        </CardLayoutTwo>
      )}
    </>
  );
}

// tailwind styles
// const tw = {
//   container: 'p-4 py-5 bg-white rounded-xl fadeIn relative hover:shadow-lg shadow-sm'
// };

// styled-c
const CardLayoutTwo = styled.div``;

const CardLayoutOne = styled.div`
  width: 83%;
  margin-top: 10px;
  .dropdown_trigger {
    height: 60px;
    background: #fff;
    box-shadow: 0 1px 1px 0 #b3b6ba1a, 0 1px 1px 0 #b3b6ba1a, 0 1px 1px 0 #b3b6ba1a;
  }

  .dropdown_body {
    /* background: #fff; */
    margin-top: 1px;
    visibility: visible;
    height: auto;
    padding: 16px;
    opacity: 1;
    transition: opacity 0.4s linear;

    .items {
      opacity: 1;
      transition: opacity 0.2s linear;
    }

    .item {
      margin-top: 10px;
      margin-bottom: 15px;
    }
  }
`;

export default StakeCard;
