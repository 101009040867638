import React from 'react';
import lens from '../../assets/icons/lens.svg';
import caret from '../../assets/icons/carret.svg';
import ProductCard from './components/card.jsx/index.jsx';
import Navbar from '../../components/Navbar';
import { Wrapper, tw } from './style';
import Footer from '../../components/footer';
import avatar from '../../assets/images/market/avatarB.svg';
import CardDetails from './components/cardDetails';
import cartIcon from '../../assets/icons/market/cart.svg'






// component
const Market = ({ children, cog }) => {
  const [states, setStates] = React.useState({
    showCardDetails: false,
    isRibbon: true,
    cardData:{}
  });

  function toggleShowCardDetails(cardData){
    setStates({...states, showCardDetails:!states.showCardDetails})
  }

  const keywords = [
    'Covid-19',
    'cancer',
    'Autonomous Nervous System',
    'diabetes',
    'Hematology',
    'Covid-19',
    'cancer',
    'Autonomous Nervous System',
    'diabetes',
    'Hematology'
  ];

  const publishers = [
    {
      image: '',
      name: 'TUYIL Pharmaceuticals',
      pub: '50 ',
      sales: '170 000 '
    },
    {
      image: '',
      name: 'Get well Hospital',
      pub: '50 ',
      sales: '170 000 '
    },
    {
      image: '',
      name: 'Big Pharma',
      pub: '50 ',
      sales: '170 000 '
    },
    {
      image: '',
      name: 'Neuro Assets',
      pub: '50 ',
      sales: '170 000 '
    },
    {
      image: '',
      name: 'Africa Psych, Org.',
      pub: '50 ',
      sales: '170 000 '
    },
    {
      image: '',
      name: 'Genetics Uni',
      pub: '50 ',
      sales: '170 000 '
    }
  ];

  return (
    <>
      <Navbar />
      <Wrapper className={tw.container}>
        {/* subnav */}
        <div className={tw.subnav}>
          <div className="tab  flex s-b midle bg-[#FAFAFA]">
            <button
              onClick={() => setStates({ ...states, isRibbon: true })}
              className={` ${states.isRibbon && 'active'} btn3`}
            >
              Ribbon
            </button>
            <button
              onClick={() => setStates({ ...states, isRibbon: false })}
              className={` ${!states.isRibbon && 'active'} btn3 `}
            >
              Published
            </button>
          </div>
          <div className="search_box  mx-4 h-full py-1">
            <div className={tw.inputField}>
              <input type="text" placeholder="e.g bobby" className="field w-full h-full p-4" />{' '}
              <img role="button" src={lens} alt="" className="absolute right-4" />
            </div>
          </div>
          <div className="filter ctd">
            <button className={tw.button}>
              All <img src={caret} alt="" />
            </button>
          </div>
        </div>

        {states.showCardDetails ? (
          <CardDetails isRibbon={states.isRibbon} toggleShowCardDetails={toggleShowCardDetails} />
        ) : (
          <>
            {/*banner*/}
            <div className="banner mt-2 w-full relative ">
              <div className={tw.overlay}>
                <div className="  text-center text-white">
                  <h1 className="h2">market</h1>
                  <p className="mt-4 ">Publish and sell your health data in the marketplace.</p>
                </div>
              </div>

              <button><img src={cartIcon} alt="" className='cartIcon absolute'/></button>
            </div>

            {/*market data*/}
            <div className="grid grid-cols-4 gap-7 mt-8 px-28 ">
              {Array(16)
                .fill(1)
                .map((item, index) => (
                  <ProductCard click={toggleShowCardDetails} withName={!states.isRibbon} />
                ))}
            </div>

            <div className=" mt-11  flex">
              <button className="btn1 w-48 mx-auto px-28">view more</button>
            </div>

            {/* popular searches */}

            <h1 className="text-gray-800 mt-11 text-center font-abld text-2xl">Popular Searches</h1>
            <div className="pop-s px-28 py-3 mt-3 mb-20 bg- flex flex-wrap justify-center">
              {keywords.map((item, index) => (
                <div className="pill ml-4 mt-3" key={index}>
                  {item}
                </div>
              ))}
            </div>
            {/* top publisher */}
            {!states.isRibbon && (
              <div className="top_pub  mt-11 my-6 px-28 py-5">
                <h1 className="text-gray-800  text-center font-abld text-2xl">Top Publishers</h1>

                <div className="peoples_card grid grid-cols-3 gap-8 shadow-lg mt-4 p-4 rounded-lg">
                  {publishers.map((item, index) => (
                    <div className="pub_card  midle p-2">
                      <div className="media h-24 w-24  ">
                        <img src={avatar} alt="" className="pub_img fsize rounded-full" />
                      </div>
                      <div className="info ml-4">
                        <p className="nameTxt">{item.name} </p>
                        <span className="midle ">
                          <b className="text-gray-700 font-abk">{item.pub}</b>
                          <p className="ml-2 text-gray-500"> Publications</p>{' '}
                        </span>
                        <span className="midle ">
                          <b className="text-gray-700 font-abk">{item.sales}</b>
                          <p className="ml-2 text-gray-500"> sales</p>{' '}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        )}

        {/* footer */}
        <Footer />
      </Wrapper>
    </>
  );
};

export default Market;
