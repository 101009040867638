import styled from 'styled-components';
import React from 'react';
import book from '../../../../assets/images/market/books.png';
import avatar from '../../../../assets/images/market/pub.png';
import ProductCard from '../card.jsx';
import PurchaseModal from './Modal';

const ProductDeatails = ({ isRibbon, toggleShowCardDetails }) => {
  const [states, setStates] = React.useState({ isBuying: false, loading: false });
  // const _N = useNavigate();

  function toggleModal() {
    setStates({ ...states, isBuying: !states.isBuying });
  }

  const address = 'ROVJGISIGIHIJFJSIFIGPISPIFGIFYYOIYOINOUH';

  return (
    <React.Fragment>
      {states.isBuying && <PurchaseModal toggleModal={toggleModal} />}
      <Wrapper className={tw.container}>
        <div className="nav mx-auto w-10/12 flex-start">
          <button onClick={() => toggleShowCardDetails()} className="midle">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M20 8.75H4.7875L11.775 1.7625L10 0L0 10L10 20L11.7625 18.2375L4.7875 11.25H20V8.75Z"
                fill="#4D545D"
              />
            </svg>
            <p className="ml-3 capitalize text-xl">go back</p>
          </button>
        </div>

        <div className={tw.card}>
          <div className="media_box 1/2  h-full">
            <div className={tw.media}>
              <img src={book} alt="" className="thumbnail rounded-2xl fsize" />
            </div>
            <p className="body1 mt-2">PDF and Epub download File (40mb)</p>
          </div>
          {/* middle */}
          <div className={tw.details}>
            <h1 className="header2">
              Raw Covid- 19 Research
              <br />
              Health Data
            </h1>

            {!isRibbon ? (
              <div className={tw.publisher}>
                <img src={avatar} alt="" className="w-8 h-8" />
                <div className="my-4 ml-3">
                  <p className="body3">Publisher</p>
                  <p className="b3 text-black">TUYIL Pharmaceuticals</p>
                </div>
              </div>
            ) : (
              <p className="body3 my-3">Ribbon Publications</p>
            )}

            <div className="summary">
              <p className="b1">
                Dolor eiusmod reprehenderit amet magna sint irure ea veniam. Consectetur irure aute ea dolor laborum ex
                velit laboris magna minim ut pariatur ex. Nulla deserunt sint fugiat officia laboris laborum. Nulla ea
                elit cillum id occaecat adipisicing minim ex aliquip Nulla ea elit cillum id occaecat adipisicing minim
                ex aliquip....
              </p>
            </div>

            <div className={tw.action}>
              <h1 className="text_sec">10.00 GHF</h1>

              <div className="full midle ml-3 ">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0 1.005L3.75 4.76L5.75 2.76L9.295 6.745L10 6.04L5.75 1.26L3.75 3.26L0.75 0.254995L0 1.005ZM0.75 3.255L3.75 6.26L5.75 4.26L10 9.04L9.295 9.745L5.75 5.76L3.75 7.76L0 4.005L0.75 3.255Z"
                    fill="#80858C"
                  />
                </svg>
                <p className=" ml-3 font-abk text-sm text-typo-1">$ 1200.00</p>
              </div>

              <span className="midle  p-4 cto_btn">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.6625 8.75C12.225 8.75 12.72 8.4425 12.975 7.9775L15.66 3.11C15.9375 2.615 15.5775 2 15.0075 2H3.9075L3.2025 0.5H0.75V2H2.25L4.95 7.6925L3.9375 9.5225C3.39 10.5275 4.11 11.75 5.25 11.75H14.25V10.25H5.25L6.075 8.75H11.6625ZM4.62 3.5H13.7325L11.6625 7.25H6.3975L4.62 3.5ZM5.25 12.5C4.425 12.5 3.7575 13.175 3.7575 14C3.7575 14.825 4.425 15.5 5.25 15.5C6.075 15.5 6.75 14.825 6.75 14C6.75 13.175 6.075 12.5 5.25 12.5ZM12.75 12.5C11.925 12.5 11.2575 13.175 11.2575 14C11.2575 14.825 11.925 15.5 12.75 15.5C13.575 15.5 14.25 14.825 14.25 14C14.25 13.175 13.575 12.5 12.75 12.5Z"
                    fill="#FBBC05"
                  />
                </svg>
                <p className="text_tertiary ml-2">Add to Cart</p>
              </span>
            </div>

            <div className="btn_group w-full midle">
              <button className="market_btn_ghost">
                {' '}
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M15 3H3C2.1675 3 1.5075 3.6675 1.5075 4.5L1.5 13.5C1.5 14.3325 2.1675 15 3 15H15C15.8325 15 16.5 14.3325 16.5 13.5V4.5C16.5 3.6675 15.8325 3 15 3ZM15 13.5H3V9H15V13.5ZM15 6H3V4.5H15V6Z"
                    fill="#4285F4"
                  />
                </svg>
                <p className="ml-2">Buy with Card</p>
              </button>
              <button onClick={toggleModal} className="market_btn ml-8">
                {' '}
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M21 7.28V5C21 3.9 20.1 3 19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V16.72C21.59 16.37 22 15.74 22 15V9C22 8.26 21.59 7.63 21 7.28ZM20 9V15H13V9H20ZM5 19V5H19V7H13C11.9 7 11 7.9 11 9V15C11 16.1 11.9 17 13 17H19V19H5Z"
                    fill="white"
                  />
                  <path
                    d="M16 13.5C16.8284 13.5 17.5 12.8284 17.5 12C17.5 11.1716 16.8284 10.5 16 10.5C15.1716 10.5 14.5 11.1716 14.5 12C14.5 12.8284 15.1716 13.5 16 13.5Z"
                    fill="white"
                  />
                </svg>{' '}
                <p className="ml-2">Buy Now</p>
              </button>
            </div>
          </div>
        </div>

        <div className={tw.address}>
          <div className={tw.box1}>
            <span className="midle">
              <p className="body1 w-40">Contract Address</p>
              <p className="b1 ml-2">00xyz243567frtdfghnserte99de</p>
            </span>

            <span className="midle mt-3">
              <p className="body1 w-40">Token ID</p>
              <p className="b1 ml-2 font-abld text-xl text-gray-700">500012334</p>
            </span>
          </div>

          {!isRibbon && (
            <div className={tw.copy}>
              <div className={tw.publisher}>
                <img src={avatar} alt="" className="w-8 h-8" />
                <div className="my-4 ml-3">
                  <p className="body3">Publisher</p>
                  <p className="b3 text-black">TUYIL Pharmaceuticals</p>
                </div>
              </div>

              <div className="wallet text-center midle">
                <p className="text-sm text-gray-500 font-abld uppercase">
                  {address.slice(0, 19) + '.......' + address.slice(address.length - 4, address.length)}
                </p>{' '}
                <svg
                  role="button"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="ml-2"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 1H4C2.9 1 2 1.9 2 3V17H4V3H16V1ZM19 5H8C6.9 5 6 5.9 6 7V21C6 22.1 6.9 23 8 23H19C20.1 23 21 22.1 21 21V7C21 5.9 20.1 5 19 5ZM19 21H8V7H19V21Z"
                    fill="#80858C"
                  />
                </svg>
              </div>
            </div>
          )}
        </div>

        <div className="more w-10/12 mx-auto my-20">
          <h1 className="header3">More Like this</h1>

          <div className="grid grid-cols-4 gap-4 mt-11">
            {Array(4)
              .fill(1)
              .map((item, index) => (
                <ProductCard />
              ))}
          </div>
        </div>
      </Wrapper>
    </React.Fragment>
  );
};

// tailwind styles
const tw = {
  container: 'bg-white relative rounded-xl  relative  p-6 ',
  card: 'w-10/12  mx-auto p-4 ctd strictFadeIn rounded-xl shadow mt-11',
  details: 'w-1/2  h-full p-4 ml-2 strictFadeIn',
  media: 'img_box strictFadeIn rounded-2xl ',
  publisher: 'midle',
  action: 'midle my-6',
  address: ' w-10/12 my-11 p-6 mx-auto s-b',
  copy: 'w-60 '
};

// styled-c
const Wrapper = styled.div`
  .img_box {
    width: 450px;
    height: 450px;
  }

  .cto_btn {
    margin-left: 10%;
  }
`;

export default ProductDeatails;
