import styled from 'styled-components';
import React from 'react';
import helpIcon from '../../../assets/icons/helpIcon.svg';
import rangeThumb from '../../../assets/images/rangeThumb.png';

import StartusLoader from './statusLoader'

const Liqcard = ({ createBond, cog, swap, mint, done }) => {
  const [showModal, setShowModal] = React.useState(!true);

  return (
    <>
      {showModal && <StartusLoader toggleModal={setShowModal} done={done}/>}

      <Wrapper className={tw.container}>
        <div className="main_card fsize bg-white  radius p-9 ">
          <div className="tabs s-b  ">
            <div className="midle">
              <button onClick={() => createBond((p) => !p)}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M20 8.75H4.7875L11.775 1.7625L10 0L0 10L10 20L11.7625 18.2375L4.7875 11.25H20V8.75Z"
                    fill="#4D545D"
                  />
                </svg>
              </button>
              <h1 className="font-abld text-gray-800 text-xl ml-3">Create Bond</h1>
            </div>

            <button className="cog_cont ">
              <img src={helpIcon} alt="cog" />
            </button>
          </div>

          {/* pool name */}
          <div className="my-2 mt-8 w-full p-4 bg-[#4285F41a]">
            <h1 className="text">Bond Pool Name</h1>
          </div>
          {/* pool name */}

          {/* select pair */}
          <div className="select-p my-4">
            <h1 className="text">Select Paire</h1>

            <div className="s-b mt-4">
              <div className="bb s-b w-48 p-3">
                <div className="flex">
                  <img className="w-8" src={rangeThumb} alt="" />

                  <h1 className="text ml-2">RBN</h1>
                </div>
                {/*  */}
                <span className="caret">
                  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1.41 0.589996L6 5.17L10.59 0.589996L12 2L6 8L0 2L1.41 0.589996Z"
                      fill="black"
                      fill-opacity="0.75"
                    />
                  </svg>
                </span>
              </div>

              {/*  */}
              <div className="bb s-b w-48 p-3">
                <div className="flex">
                  <img className="w-8" src={rangeThumb} alt="" />

                  <h1 className="text ml-2">RBN</h1>
                </div>
                {/*  */}
                <span className="caret">
                  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1.41 0.589996L6 5.17L10.59 0.589996L12 2L6 8L0 2L1.41 0.589996Z"
                      fill="black"
                      fill-opacity="0.75"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          {/* select pair */}

          {/* select pool */}

          <div className="select-pool my-11">
            <h1 className="text">Select Pool</h1>

            <p className="body1 my-2">Select a pool type based on your preffered liquidity provider fee</p>

            <div className="grid gap-3 grid-cols-3 my-4">
              <div className="card-s bb p-4  flex-col justify-left">
                <h1 className="text">0.05% fee</h1>
                <p className="body1"> Best for stable pairs</p>
              </div>

              <div className="card-s bb p-4  flex-col justify-left">
                <h1 className="text">0.05% fee</h1>
                <p className="body1"> Best for stable pairs</p>
              </div>

              <div className="card-s bb p-4  flex-col justify-left">
                <h1 className="text">0.05% fee</h1>
                <p className="body1"> Best for stable pairs</p>
              </div>
            </div>
          </div>
          {/* select pool */}

          {/* select range */}

          <div className="select-p-raange my-2">
            <h1 className="text">Select Price Range</h1>

            <p className="body1 my-3">
              Your Bond will only earn fees when the market price of the pair is within your range
            </p>

            <div className="w-full  flex items-center justify-evenly">
              <div className="each-item p-2">
                <p className="body1">Minimum Price</p>
                <div className=" mt-2 w-full p-2 bg-[#4285F42a]">
                  <h1 className="body1">0.2490876</h1>
                </div>
              </div>

              <div className="each-item p-2">
                <p className="body1">Minimum Price</p>
                <div className=" mt-2 w-full p-2 bg-[#4285F42a]">
                  <h1 className="body1">0.2490876</h1>
                </div>
              </div>

              <p className="text-pri-main font-aexb">RBN Price</p>
              <p className="text-gray-500 font-aexb">RBN Price</p>
            </div>

            {/* current price */}

            <div className="border my-4 border-gray-300 rounded w-full p-3 s-b px-6">
              <p className="body2">Current Price</p>
              <p className="text">0.000000005 </p>
            </div>
            {/* current price */}
          </div>
          {/* select range */}

          <div className=" w-full my-6 mt-11" >
            <button className="btn1 w-full " onClick={() => setShowModal(p => !p)}>Create Bond</button>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

// tailwind styles
const tw = {
  container: 'bg-white relative radius fadeIn relative mx-auto'
};

// styled-c
const Wrapper = styled.main`
  width: 600px;
  /* height: 450px; */
  /* min-height: 700px; */
  margin-top: 10%;
  padding: 1px;
  background-image: linear-gradient(to bottom, #4285f4, #d90d5a);

  .price {
    background: #fafafa;
    top: 20px;
    left: -220px;

    .inner_block {
      border-left: 3px solid #d90d5a;
    }
  }

  .tab_cont {
    width: 200px;
    height: 39px;
    background: #dddddd49;
    button {
      text-transform: capitalize;
      color: #343b46;

      &.active {
        background: #4286f4;
        border-radius: 20px;
        color: #fff;
      }
    }
  }
`;

export default Liqcard;
