import styled from 'styled-components';
import lines from '../../assets/icons/hero/lines.png';
import shapes from '../../assets/icons/bg.svg';

const Wrapper = styled.section`
  width: 100%;
  .land {
    height: auto;
    .hero {
      width: 100%;
      /* height: ; */
      background-image: url(${lines});
      background-position: center;
      background-size: cover;
      margin-top: 3.6rem;
      .content {
        max-width: 48.18rem;

        .main {
          font-size: 3.75rem;
          line-height: 4.75rem;
        }
        /* margin-top: -80px; */
        p {
          max-width: 40.3125rem;
          font-size: 1.125rem;
          line-height: 1.68rem;
          /* width: 80%; */
        }
      }
      .features {
        /* bottom: -380px;
        left: 50%;
        transform: translateX(-50%);
        width: 80%;
        p {
          width: 60%;
        } */
        .main {
          font-size: 2.56rem;
        }

        .b1 {
          font-size: 1.125rem;
          line-height: 1.56rem;
          max-width: 28.3rem;
        }
      }
    }
  }

  .details {
    /* height: auto; */
    /* margin-top: 320px; */

    /* .list-cont {
      width: 400px;
    }
    .section_img {
      width: 60%;
    } */

    h1 {
      font-size: 1.75rem;
    }

    .list-cont {
      max-width: 29.56rem;
    }
    
    p {
      font-size: 1.125rem;
      line-height: 1.56rem;
    }
  }

  .protocol {
    h1 {
      font-size: 1.75rem;
    }

    background-image: url(${shapes});
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    height: 120vh;
    .counts {
      width: 50%;
      .grad {
        color: blue;
        -webkit-text-fill-color: transparent; /* Webkit Disable Fallback */

        background: -webkit-linear-gradient(pink, darkblue);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .card {
      width: 70%;
      height: 240px;
      z-index: 10;
    }
    .img-one {
      left: 0;
      bottom: -60px;
      z-index: 9;
    }
    .img-two {
      bottom: -150px;
      right: 0;
      z-index: 9;
    }
  }
  
  
  footer{
    height:368px;
    h1, p, .nav {
      color: #fff;
    }

    p, .nav {
      opacity: .7;
    }
  }
`;

const Box = styled.section`
  border: 1px solid ${({ bcl }) => bcl};
`;

const tw = {
  logo: 'mx-auto',
  box: 'p-1 scaleInOut',
  wrapper: ''
};

export { tw as default, Wrapper, Box };
