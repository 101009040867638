// axios instance creation

// mock api to return user data
export function getUserDetails() {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        user: {
          name: 'bobby',
          connected: true,
          address: 'bc1qehtjvdyzm39j9x8l3tu6vc9lcuu2t5c72furh5',
          ballance: 4569.0
        }
      });
    }, 2000);
  });
}
