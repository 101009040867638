import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { fetchCount } from '../../features/counter/counterAPI';
import { getUserDetails } from '../../api';

const initialState = {
  user: { name: '', connected: false, address: '', ballance: 50000},
  loading: false
};



export const getUserWallet = createAsyncThunk('auth/getWallet', async (userId) => {
  const response = await getUserDetails();
  // The value we return becomes the `fulfilled` action payload
  userId('wallet connected')
  return response;
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    LOADING_START: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.status = 'loading';
    },
    ConnectToWallet: (state) => {
      state.value -= 1;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getUserWallet.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserWallet.fulfilled, (state, action,) => {
        state.loading = false;

        state.user = action.payload.user;
        
      });
  }
});

export const { LOADING_START, ConnectToWallet, incrementByAmount } = authSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const loadingStatus = (state) => state.status;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default authSlice.reducer;
