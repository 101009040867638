import logo from '../../assets/icons/bg.svg';
import styled from 'styled-components';
import React from 'react';
import Navbar from '../Navbar';
const tw = {
  container: 'fscrn bg-white dark:bg-gray-600 relative ',
  page: 'fsize page border overflow-y-hidden ',
  subpages: 'subpages  w-full mt-16 '
};

const Layout = ({ children, auto }) => {
  return (
    <Wrapper auto={auto} className={tw.container}>
      <Navbar />
      <div className={tw.page}>
        <div className={tw.subpages}>{children}</div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.main`
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;
    /* height: 200vh; */
    height: ${({auto}) => !auto ? "100vh" : "150vh"};



  .subpages {
    /* height: ${({auto}) => !auto ? "calc(100vh - 66px)" : "calc(150vh - 66px)"}; */
    height:calc(100vh - 66px) ;
  }
`;

export default Layout;
