import styled from 'styled-components';
import React from 'react';
import rib from '../../../assets/icons/liquidity/rib.svg';
import eth from '../../../assets/icons/liquidity/eth.svg';
import caret from '../../../assets/icons/carret.svg';
// import cake from '../../../assets/icons/CAKE - PancakeSwap Token.png';
// import solana from '../../../assets/icons/SOL - Solana.svg';
// import { NavLink } from 'react-router-dom';

const EmptyPool = ({ children, cog, addLiq,removeLiq }) => {
  const [isConnected, ] = React.useState(false);
  const [userPool, ] = React.useState(false);



  return (
    <>
      <Wrapper className={tw.container}>
        {userPool ? (
          <div className="notice_board w-full rounded-xl bg-[#FAFAFA] py-12">
            {isConnected ? (
              <h1 className="b1">
                {' '}
                <span className="redtext"> Connect to Wallet </span>to view Liquidity Pool
              </h1>
            ) : (
              <h1 className="text-[#FD0404] font-abk">No Liquidity found</h1>
            )}
          </div>
        ) : (
          <div className="pool_cont rounded-lg w-full p-6   bg-[#fee7ef4a]">
            <DropdownComponent add={addLiq} remove={removeLiq}  />
            <DropdownComponent add={addLiq}  remove={removeLiq}/>
            <DropdownComponent add={addLiq}  remove={removeLiq}/>
          </div>
        )}

        <button onClick={addLiq} className="btn1 w-full mt-8">Add Liquidity</button>

        <p className="body1 mt-6">
          Don’t see a Pool you joined? <span className="text-blu-1">Import it</span>
        </p>

        <div className="bellow  p-2 rounded mt-8 text-left">
          <div className="inner_block p-2">
            <h1 className="capitalize body1">
              When you add liquidity, you'll earn 0.25% of all trades on this pair proportional to your share of the
              pool. Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity.
            </h1>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

function DropdownComponent({add, remove}) {
  // const dropdownBodyRef = React.useRef();
  const [active, setActive] = React.useState(false);

  return (
    <Dropwrapper className={`mx-auto ${active && 'active'}`}>
      <button onClick={() => setActive((p) => !p)} className="dropdown_trigger p-3  s-b w-full  mx-auto">
        <div className="tokens  midle">
          <div className="thum_box midle">
            <img src={rib} alt="" className="thumbIcon w-9 h-9" />
            <img src={eth} alt="" className="thumbIcon w-9 h-9" />
          </div>
          <h1 className="header4 ml-2">RBN/ETH</h1>
        </div>
        <div className="icon">
          <img src={caret} alt="" />
        </div>
      </button>
      <div className="dropdown_body ">
        <div className="w-full items">
          <div className="item s-b">
            <div className="name midle">
              <img src={eth} alt="" className="w-6 h-6" />
              <p className="ml-4 body1">Pooled RBN</p>
            </div>

            <p className="text">3.74354</p>
          </div>

          <div className="item s-b">
            <div className="name midle">
              <img src={eth} alt="" className="w-6 h-6" />
              <p className="ml-4 body1">Pooled BNB</p>
            </div>

            <p className="text">0.74354567</p>
          </div>

          <div className="item s-b">
            <p className=" body1">Your Pool Tokens</p>

            <p className="text">0.012345</p>
          </div>

          <div className="item s-b">
            <p className=" body1">Your Share of Pool</p>

            <p className="text">0.01%</p>
          </div>

          <div className="btn_box  mt-2 s-b">
            <button onClick={add} className="btn_liq">add</button>
            <button   onClick={remove} className="btn_liq_ghost">remove</button>
          </div>
        </div>
      </div>
    </Dropwrapper>
  );
}

// tailwind styles
const tw = {
  container: 'w-full h-full  p-4 mt-4 strictFadeIn text-center',
  label: 'label s-b p-4 py-9 h-14 bg-red-400 my-3 radius'
};

// styled-c
const Wrapper = styled.section`
  /* color: #1a222f; */

  .notice_board {
  }

  .bellow {
    background: #fafafa;
  }
  .inner_block {
    border-left: 3px solid #d90d5a;
  }
  a {
    /* background: red; */
    background: ${({ bgc }) => bgc || ''};
  }
`;

const Dropwrapper = styled.div`
  width: 83%;
  margin-top: 10px;
  .dropdown_trigger {
    height: 60px;
    background: #fff;
    box-shadow: 0 1px 1px 0 #b3b6ba1a, 0 1px 1px 0 #b3b6ba1a, 0 1px 1px 0 #b3b6ba1a;
  }

  .dropdown_body {
    background: #fff;
    margin-top: 1px;
    height: 0;
    padding: 0;
    visibility: hidden;
    transition: all 0.4s linear;

    .items {
      opacity: 0;
    }

    .item {
      margin-top: 10px;
    }
  }

  &.active {
    .dropdown_body {
      background: #fff;
      margin-top: 1px;
      visibility: visible;
      height: 230px;
      padding: 16px;
      opacity: 1;
      transition: opacity 0.4s linear;

      .items {
        opacity: 1;
        transition: opacity 0.2s linear;
      }
    }
  }
`;

export default EmptyPool;
