import styled from 'styled-components';
import React from 'react';
import times from '../../../../../../assets/icons/times.svg';
import timesW from '../../../../../../assets/icons/timesW.svg';
import logo from '../../../../../../assets/icons/Group 2782.svg';
import rib from '../../../../../../assets/icons/liquidity/rib.svg';
import eth from '../../../../../../assets/icons/liquidity/eth.svg';
// import { Portal } from 'react-portal';
// import { Oval } from 'react-loader-spinner';
// import { css } from '@emotion/react';
import MoonLoader from 'react-spinners/MoonLoader';
// import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

// const AFFIRM = 'AFFIRM';

const CardLayout = ({ toggleModal, status, data, confirm }) => {
  const [states, setStates] = React.useState({ confirming: true, loading: false });

  function IsMinting(progress) {
    setStates({ ...states, confirming: false, loading: true });
    setTimeout(() => {
      setStates({ ...states, confirming: false, loading: false });
    }, 2000);
  }

  // function disableBodyScroll(){
  //   let bodyEl = document.body
  //   bodyEl.style.overflow = "hidden";
  // }

  return (
    <Wrapper className={tw.container}>
      {states.confirming ? (
        <Confirmation toggleModal={toggleModal} mint={IsMinting} />
      ) : states.loading ? (
        <Loader toggleModal={toggleModal} />
      ) : (
        <Success toggleModal={toggleModal} />
      )}
    </Wrapper>
  );
};

function Confirmation({ toggleModal, mint }) {
  return (
    <div className="card bg-white rounded h-48 w-48 radius fadeIn">
      <div className="blue bg-[#073278] w-full h-1/2 rounded-t-xl p-9 flex flex-col relative">
        <div className="action s-b p-1">
          <p className="text-white capitalize">you'll receive</p>
          <button className="times" onClick={() => toggleModal(false)}>
            <img src={times} alt="" />
          </button>
        </div>

        <div className="token midle mt-4">
          <p className="text-white text-3xl ">0.043257658</p>
          <div className="thum_box midle">
            <img src={rib} alt="" className="thumbIcon w-9 h-9" />
            <img src={eth} alt="" className="thumbIcon w-9 h-9" />
          </div>
        </div>

        <p className="uppercase text-sm mt-1 text-gray-100 font-abk">RBN / BNB Pool Tokens</p>

        <div className="bellow  p-2 rounded mt-6">
          <div className="inner_block p-2">
            <h1 className="body1 capitalize">
              Output is in estimates. If price changes by more than 0.5%, your transaction will revert.
            </h1>
          </div>
        </div>

        {/* <div className="absolute rounded -bottom-4 bg-[#FAFAFA] h-11 bg w-10/12"></div> */}
      </div>
      <div className="white bg-white w-full h-auto rounded-b-xl  p-11">
        <div className="h-auto bb my-3 mini_card rounded">
          <div className="inner_box w-full h-full bg-white rounded p-4">

            <h1 className="header4 ">Overview</h1>

              <div className="w-full items mt-4">
          <div className="item s-b">
            <div className="name midle">
              <img src={eth} alt="" className="w-6 h-6" />
              <p className="ml-4 body1">Pooled RBN</p>
            </div>

            <p className="text">3.74354</p>
          </div>

          <div className="item s-b my-2">
            <div className="name midle">
              <img src={rib} alt="" className="w-6 h-6" />
              <p className="ml-4 body1">Pooled BNB</p>
            </div>

            <p className="text">0.74354567</p>
          </div>

          {/* <div className="item s-b my-2">
            <p className=" body1">Your Pool Tokens</p>

            <p className="text">0.012345</p>
          </div> */}

          <div className="item my-2 s-b">
            <p className=" body1">Your Share of Pool</p>

            <p className="text">0.01%</p>
          </div>

          <div className="btn_box  mt-3  items-start flex justify-between ">
          <h1 className="body1">Rates</h1>
          <div className="box text-right ">
            <h1 className="text">1 BNB =  0.000001 RBN</h1>
            <h1 className="text">1 RBN = 28.2 BNB</h1>
          </div>
          </div>
        </div>

          </div>
        </div>

        <button onClick={mint} className="btn1 w-full mt-2">
          Confirm supply
        </button>
      </div>
    </div>
  );
}

function Loader({ toggleModal }) {
  return (
    <div className="card bg-white rounded h-48 w-48 radius p-9  flex flex-col justify-between fadeIn">
      <div className="action s-b p-1">
        <p className="text-white capitalize"></p>
        <button className="times" onClick={() => toggleModal(false)}>
          <img src={timesW} alt="" />
        </button>
      </div>

      <div className="w-full h-auto  flex flex-col items-center text-[#1A222F]">
        <div className="loader  ctd relative ">
          <img src={logo} alt="" className="w-11 h-11 absolute" />
          <MoonLoader color="#4286f4" size={80} />
        </div>
        <p className="wait font-abk mt-11 text-[#1A222F] text-xl">Waiting for Confimation</p>
        <p className="wait font-abld text-lg  my-1">Minting 3.745 RBN to 0.7435 AFYA</p>
        <p className="conf text-[#676C74] text-sm">Confirm this transaction in your Wallet</p>
      </div>
    </div>
  );
}

function Success({ toggleModal }) {
  return (
    <div className="card bg-white rounded h-48 w-48 radius p-9  flex flex-col justify-between fadeIn">
      <div className="action s-b p-1">
        <p className="text-white capitalize"></p>
        <button className="times" onClick={() => toggleModal(false)}>
          <img src={timesW} alt="" />
        </button>
      </div>

      <div className="w-full h-auto  flex flex-col items-center text-[#1A222F]">
        <p className="h3  mt-11">Transaction Completed</p>
        <p className="wait mt-1 text-[#34A853]">View Transaction</p>
        <button onClick={() => toggleModal(false)} className="btn1 text-[#676C74] w-full mt-4">
          Close
        </button>
      </div>
    </div>
  );
}

// tailwind styles
const tw = {
  container: 'h-screen w-screen flex justify-center strictFadeIn'
};

// styled-c
const Wrapper = styled.main`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  background: #0007;

  .card {
    width: 500px;
    height: 400px;
    margin-top: 5%;

    .mini_card {
      background-image: linear-gradient(to bottom, #4285f4, #d90d5a);
      padding: .3px;
      .inner-box {
      }
    }

    .loader {
      width: 150px;
      height: 150px;

      img {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .bellow {
      background: #fafafa;
    }
    .inner_block {
      border-left: 3px solid #d90d5a;
    }
  }
`;

export default CardLayout;
