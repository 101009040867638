import styled from 'styled-components';
import React from 'react';
import caret from '../../../../../assets/icons/carret.svg';
import swaps from '../../../../../assets/icons/liquidity/plus.svg';
import rib from '../../../../../assets/icons/liquidity/rib.svg';
import eth from '../../../../../assets/icons/liquidity/eth.svg';
// import cake from '../../../../assets/icons/CAKE - PancakeSwap Token.png';
// import StatusLoader from '../../../../components/modals/statusLoader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { getUserWallet } from '../../../../redux/slice/authSlice';
// import { useSelector } from 'react-redux';

// import { loadingStatus } from '../../../../redux/slice/authSlice';

const AddLiq = ({ children, setShowModal, back }) => {
  // const [setShowLoader] = React.useState(false);
  // const [states, setStates] = React.useState({ connected: true, minting: true });
  // const _D = useDispatch();
  // const userState = useSelector((state) => state.auth);
  const arr = Array(8).fill(8);

  // constants
  // let User = userState.user;


  return (
    <>
      {/* {!showLoader && <StatusLoader toggleModal={setShowLoader} />} */}
      <ToastContainer />
      <Wrapper className={tw.container}>
        <div className="from  px-9 p-2">
          <div className="flex justify-between mt-3">
            <div className="box">
              <h1 className="body1">input</h1>
              <Dropdown className="midle p-1 mt-1 px-4 bg-sec-100 relative">
                <h1 className="select text">RBN</h1>
                <img src={caret} alt="" className="ml-6" />
                <div className="tokens absolute ">
                  {arr.map((item, index) => (
                    <div key={index} className="token border midle my-1">
                      <img src="" alt="" className="border w-6 h-6" />
                      <h1 className=" uppercase ml-2">eth</h1>
                    </div>
                  ))}
                </div>
              </Dropdown>
            </div>

            <div className="ima_box w-9 h-9">
              <img src={rib} alt="" />
            </div>
          </div>

          <span className="s-b mt-1">
            <span className="empty"></span>
            <h1 className="empty text">
              <span className="body1">Balance</span>: 4.3434 RBN
            </h1>
          </span>

          <div className="input_cont relative  h-11 mt-2">
            <input placeholder="0.0" type="text" name="" className="field w-full h-full bg-pri-50 p-3" id="" />
            <div className="btn_liq_ghost_auto btn_liq_ghost absolute right-2 top-2">
              Max
            </div>
          </div>
        </div>
        <div className="swap ctd">
          <div className="circle ctd bg-red-50 rounded-full mt-1 w-9 h-9">
            <img src={swaps} alt="" />
          </div>
        </div>

        <div className="to px-9 p-2">
          <div className="flex justify-between">
            <div className="box">
              <h1 className="body1">output</h1>
              <Dropdown className="midle p-1 mt-1 px-4 bg-sec-100 relative">
                <h1 className="select text">RBN</h1>
                <img src={caret} alt="" className="ml-6" />
                <div className="tokens absolute ">
                  {arr.map((item, index) => (
                    <div key={index} className="token border midle my-1">
                      <img src="" alt="" className="border w-6 h-6" />
                      <h1 className=" uppercase ml-2">eth</h1>
                    </div>
                  ))}
                </div>
              </Dropdown>
            </div>

            <div className="ima_box w-9 h-9">
              <img src={eth} alt="" />
            </div>
          </div>

          <span className="s-b mt-1">
            <span className="empty"></span>
            <h1 className="empty text">
              <span className="body1">Balance</span>: 4.3434 AFYA
            </h1>
          </span>

          <div className="input_cont relative h-11 mt-2">
            <input placeholder="0.0" type="text" name="" className="field w-full h-full bg-pri-50 p-3" id="" />
               <div className="btn_liq_ghost_auto btn_liq_ghost absolute right-2 top-2">
              Max
            </div>
          </div>
        </div>

        <div className="pool_prices bg-[#FAFAFA] rounded-xl midle w-full p-2 py-4 justify-around my-4">
          <p className="body1">Prices and Pool Share</p>

          <div className="text_box text-center">
            <p className="text">0.00435</p>
            <p className="body1">RBN per AFYA</p>
          </div>

             <div className="text_box text-center">
            <p className="text">0.00435</p>
            <p className="body1">RBN per AFYA</p>
          </div>

             <div className="text_box text-center">
            <p className="text">0.00435</p>
            <p className="body1">RBN per AFYA</p>
          </div>
        </div>

      
          <div className="button w-full mt-9 s-b">
            {' '}
            <button disabled={!true} onClick={() => setShowModal(true)} className="btn1 w-full txt-blu-1">
               Approve 
            </button>
            <div className="w-16"></div>
            <button disabled={true} className="btn1 w-full txt-blu-1">
             Join Pool
            </button>
          </div>
  
      </Wrapper>
    </>
  );
};

const tw = {
  container: 'w-full h-full strictFadeIn '
};

const Wrapper = styled.main`
  .field {
    &:focus {
      outline: none;
    }
  }
`;
const Dropdown = styled.button`
  &:hover {
    .tokens {
      background-color: #fff;
      width: 140px;
      height: auto;
      top: 38px;
      left: 0;
      display: block;
    }
  }
  .tokens {
    background-color: #fff;
    width: 140px;
    height: 254px;
    top: 38px;
    left: 0;
    display: none;
  }
`;

export default AddLiq;
