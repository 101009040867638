import styled from 'styled-components';

const Wrapper = styled.nav`
  z-index: 100;

  .nav_item_container {

    .navTxt {
      &.active {
        color: #0a46a9;
      }
    }

    .dropdownCont {
      display: none;
      left: 0;
      top: 28px;
      /* z-index: ; */
      /* border: 1px solid red; */
      width: 147px;
    }

    &:hover {
      .dropdownCont {
      /* width: 100%; */
        display: flex;
        font-size:16px;
      }
    }
  }
`;

const tw = {
  wrapper: 'h-16 shadow flex s-b px-14 fixed top-0 w-full bg-white ',
  nav: 'nav ml-20 flex b1',
  box: 'p-1 scaleInOut ',
  link: 'navTxt px-12  hover:text-pri-main',
  linkItem: 'px-5 p-3 hover:bg-pri-50 hover:text-grey-600'
};

export { tw as default, Wrapper };
