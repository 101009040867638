import styled from 'styled-components';
import React from 'react';
import helpIcon from '../../../assets/icons/helpIcon.svg';
import EmptyPool from './Pool';
import AddLiq from './AddLiq';
import Modal from './modals/statusLoader';
import RemoveLiq from './RemoveLiq';
import rib from '../../../assets/icons/liquidity/rib.svg';
import eth from '../../../assets/icons/liquidity/eth.svg';

const Liqcard = ({ children, cog, swap, mint }) => {
  const [addLiq, toggleAddLiq] = React.useState(true);
  const [showModal, setShowModal] = React.useState(!true);
  const [isRemoving, seIsRemoving] = React.useState(false);

  // handlers
  function removeLiq() {
    toggleAddLiq(false);
    seIsRemoving(true);
  }

  function addLiquidity() {
    toggleAddLiq(false);
    seIsRemoving(false);
  }

  return (
    <>
      {showModal && <Modal toggleModal={setShowModal}/>}

      <Wrapper className={tw.container}>
        <div className="main_card fsize bg-white  radius p-9 ">
          <div className="tabs s-b  ">
            {addLiq ? (
              <p className=" midle radius">Your Liquidity Pool</p>
            ) : (
              <div className="midle">
                <button onClick={() => toggleAddLiq((p) => !p)}>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M20 8.75H4.7875L11.775 1.7625L10 0L0 10L10 20L11.7625 18.2375L4.7875 11.25H20V8.75Z"
                      fill="#4D545D"
                    />
                  </svg>
                </button>
                <h1 className="font-abld text-gray-800 text-xl ml-3">
                  {isRemoving ? 'Remove Liquidity' : 'Add liquidity'}
                </h1>
              </div>
            )}

            <button className="cog_cont ">
              <img src={helpIcon} alt="cog" />
            </button>
          </div>

          <div className="fsize  mt-4">
            {addLiq ? (
              <EmptyPool addLiq={addLiquidity} removeLiq={removeLiq} />
            ) : isRemoving ? (
              <RemoveLiq back={() => toggleAddLiq((p) => !p)} />
            ) : (
              <AddLiq back={() => toggleAddLiq((p) => !p)} setShowModal={setShowModal} />
            )}
          </div>
        </div>
      </Wrapper>
    {!addLiq && <div className="pool-list p-4 shadow-xl w-80 mx-auto mt-4 bg-white rounded-lg ">
      <div className="  midle shadow-sm h-14">
        <h1 className=" ml-2">Your liquidiy position</h1>
      </div>
      <div className="tokens  midle  h-14">
        <div className="thum_box midle">
          <img src={rib} alt="" className="thumbIcon w-9 h-9" />
          <img src={eth} alt="" className="thumbIcon w-9 h-9" />
        </div>
        <h1 className="header4 ml-2">RBN/ETH</h1>
      </div>

      <div className="w-full items mt-1 ">
        <div className="item  s-b">
          <div className="name midle">
            <img src={eth} alt="" className="w-6 h-6" />
            <p className="ml-4 body1">Pooled RBN</p>
          </div>

          <p className="text">3</p>
        </div>

        <div className="item my-3 s-b ">
          <div className="name midle">
            <img src={rib} alt="" className="w-6 h-6" />
            <p className="ml-4 body1">Pooled ETH</p>
          </div>

          <p className="text">0</p>
        </div>

        <div className="item s-b">
          <p className=" body1">Your Pool Tokens</p>

          <p className="text">0</p>
        </div>
      </div>
    </div>}
    </>
  );
};

// tailwind styles
const tw = {
  container: 'bg-white relative radius fadeIn relative mx-auto'
};

// styled-c
const Wrapper = styled.main`
  width: 600px;
  /* height: 450px; */
  /* min-height: 700px; */
  margin-top: 10%;
  padding: 1px;
  background-image: linear-gradient(to bottom, #4285f4, #d90d5a);

  .price {
    background: #fafafa;
    top: 20px;
    left: -220px;

    .inner_block {
      border-left: 3px solid #d90d5a;
    }
  }

  .tab_cont {
    width: 200px;
    height: 39px;
    background: #dddddd49;
    button {
      text-transform: capitalize;
      color: #343b46;

      &.active {
        background: #4286f4;
        border-radius: 20px;
        color: #fff;
      }
    }
  }
`;

export default Liqcard;
