import styled from 'styled-components';
import React from 'react';
// import rangeThumb from '../../../../assets/icons/liquidity/rangeThumb.svg';
// import swaps from '../../../../assets/icons/liquidity/plus.svg';
// import rib from '../../../../assets/icons/liquidity/rib.svg';
import eth from '../../../../assets/icons/liquidity/eth.svg';
// import cake from '../../../../assets/icons/CAKE - PancakeSwap Token.png';
// import StatusLoader from '../../../../components/modals/statusLoader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { getUserWallet } from '../../../../redux/slice/authSlice';
import { useSelector } from 'react-redux';
import ReactSlider from 'react-slider';

// import { loadingStatus } from '../../../../redux/slice/authSlice';

const RemoveLiq = ({ children, cog, back }) => {
  const [setShowLoader] = React.useState(false);
  const [rangeValue, setRangeValue] = React.useState(0);
  // const [states, setStates] = React.useState({ connected: true, minting: true });
  // const _D = useDispatch();
  const userState = useSelector((state) => state.auth);
  // const arr = Array(8).fill(8);

  // constants
  let User = userState.user;

  function Mint() {
    setShowLoader(true);
  }

  const tabArr = [20, 50, 75, 100];

  return (
    <>
      {/* {!showLoader && <StatusLoader toggleModal={setShowLoader} />} */}
      <ToastContainer />
      <Wrapper className={tw.container}>
        <div className="range_box  mt-8 flex flex-col space-y-6">
          <div className="amount s-b">
            <h className="header3 ">Amount</h>
            <h className="header1">{rangeValue} %</h>
          </div>

          <div className="range">
            <ReactSlider
              className="sliderNode"
              thumbClassName="sliderThumb"
              trackClassName="sliderTrack"
                 onChange={(val, ind) => setRangeValue(val)}
              defaultValue={0}

              // renderThumb={(props, state) => <div className='' {...props}>{state.valueNow}</div>}
            />
            {/* <Range
              type="range"
              name=""
              defaultValue={0}
              className="w-full"
              id=""
              min={0}
              max={100}
              onChange={(e) => setRangeValue(e.target.value)}
            /> */}
          </div>

          <div className="tabs midle justify-around">
            <div className="tab_item rounded p-3 "></div>

            {tabArr.map((item, index) => (
              <div className={`${!true && 'bg-pri-300'} "tab_item rounded p-3 ctd  bg-pri-100 font-abld"`}>
                {item} %
              </div>
            ))}
          </div>
        </div>

        <div className="my-6 rounded summary bg-gray-50 p-4">
          <div className="dropdown_body ">
            <p className="body1 capitalize mb-4"> you will receive</p>
            <div className="w-full items">
              <div className="item s-b">
                <div className="name midle">
                  <img src={eth} alt="" className="w-6 h-6" />
                  <p className="ml-4 body1">Pooled RBN</p>
                </div>

                <p className="text">3.74354</p>
              </div>

              <div className="item s-b mt-6">
                <div className="name midle">
                  <img src={eth} alt="" className="w-6 h-6" />
                  <p className="ml-4 body1">Pooled BNB</p>
                </div>

                <p className="text">0.74354567</p>
              </div>
            </div>
          </div>
        </div>

        <div className="pool_prices bg-[#FAFAFA] rounded-xl midle w-full p-2 py-4 justify-around my-4">
          <p className="body1">Prices and Pool Share</p>

          <div className="text_box text-center">
            <p className="text">0.00435</p>
            <p className="body1">RBN per AFYA</p>
          </div>

          <div className="text_box text-center">
            <p className="text">0.00435</p>
            <p className="body1">RBN per AFYA</p>
          </div>

          <div className="text_box text-center">
            <p className="text">0.00435</p>
            <p className="body1">RBN per AFYA</p>
          </div>
        </div>

        {!User.connected ? (
          <div className="button w-full mt-9 s-b">
            {' '}
            <button disabled={!true} onClick={() => setShowLoader(true)} className="btn1 w-full txt-blu-1">
              Approving
            </button>
            <div className="w-16"></div>
            <button disabled={true} onClick={Mint} className="btn1 w-full txt-blu-1">
              {true ? 'Enter amount' : 'Remove liquidity'}
            </button>
          </div>
        ) : (
          <button disabled={true} onClick={() => setShowLoader(true)} className="btn1 w-full txt-blu-1 mt-9">
            connect to wallet
          </button>
        )}
      </Wrapper>
    </>
  );
};

const tw = {
  container: 'w-full h-full strictFadeIn '
};

const Wrapper = styled.main`
  .field {
    &:focus {
      outline: none;
    }
  }

  .track {
    &-0 {
      background: red;
    }
    &-1 {
      background: #0077ff;
    }
  }
`;
// const Range = styled.input`
//   /* -webkit-appearance: none;
//   width: 100%;
//   background: #f44285;
//   border-radius: 20px;
//   height: 16px;
//   &::-webkit-slider-runnable-track {
//     /* background-color: yellow; */
//   }

//   &::-webkit-slider-thumb {
// -webkit-appearance: none;

// height: 56px;
// width: 56px;
// /* border-radius: 50%; */
// background: transparent;
// cursor: pointer;

// position: relative;

//   } */
// `;


export default RemoveLiq;
