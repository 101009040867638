import React from 'react';
// import { gtw } from '../../assets/styles';
import logo from '../../assets/icons/logo.svg';
// import caret from '../../assets/icons/carretblue.svg';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import tw, { Wrapper } from './styles';
import { getUserWallet } from '../../redux/slice/authSlice';
import { useDispatch, useSelector } from 'react-redux';
// import { loadingStatus } from '../../redux/slice/authSlice';
import PulseLoader from 'react-spinners/PulseLoader';
import { ToastContainer, toast } from 'react-toastify';

const Nav = () => {
  const _D = useDispatch();
  const _L = useLocation();
  const _N = useNavigate();

  const userState = useSelector((state) => state.auth);

  const navList = [
    {
      name: 'swap',
      path: '/swap',
      childLink: [
        { name: 'Swap Tokens', link: '' },
        { name: 'Mint AFYA', link: '' }
      ]
    },
    {
      name: 'farm',
      path: '/farm',
      childLink: [
        { name: 'Stake LPs', link: '' },
        { name: 'Single Stake', link: '' }
      ]
    },
    {
      name: 'liquidity',
      path: '/liquidity'
    },
    {
      name: 'publish',
      // path: '/publish',
      path: '#',
      childLink: [
        { name: 'Publications', link: '' },
        { name: 'Publish', link: '' }
      ]
    },
    {
      name: 'market',
      path: '/market'
    },
    {
      name: 'finance',
      path: '/finance',
      childLink: [
        { name: 'Lend', link: '/finance/lend' },
        { name: 'Borrow ', link: '/finance/borrow' },
        { name: 'Social Bonds ', link: '/finance/bond' },
        { name: 'Synthetics', link: '/finance/synthetic' }
      ]
    }
  ];

  // effects
  // React.useEffect((

  // ),[])

  // const arr = [];
  let address = userState.user.address;

  async function doSome(txt) {
    // TODO: get userdetails
    console.log(userState);

    _D(getUserWallet(Success));
  }
  const Success = (text) => {
    toast.success(text || 'Approved Transaction In Wallet', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  const [showButton, setShowButton] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener('scroll', function () {
      const connect = document.getElementById('connect');
      if (window.scrollY > connect.offsetTop + connect.offsetHeight) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, [showButton]);

  return (
    <>
      <ToastContainer />
      <Wrapper className={tw.wrapper}>
        <div className="logo_cont midle">
          <Link to="/" className="logo">
            <img src={logo} alt="" />
          </Link>
          <nav className={tw.nav}>
            {navList.map((item, index) => (
              <div key={index} className="relative nav_item_container">
                <NavLink to={item.path} className={tw.link}>
                  {item.name}
                </NavLink>
                <div className="absolute flex flex-col dropdownCont bg-white shadow ">
                  {item.childLink?.map((item, index) => (
                    <NavLink to={item.link} key={index} className={tw.linkItem}>
                      {item.name}
                    </NavLink>
                  ))}
                </div>
              </div>
            ))}
          </nav>
        </div>

        {/* {_L.pathname === '/' ? (
          <button onClick={() => _N('/swap')} className="btn1 midle  ctd">
            Enter Ribbon DeFi
          </button>
        ) :   userState.user?.connected ? (
          <button className="btn2 midle uppercase">
            <p className="text-xs uppercase">
              {address.slice(0, 9) + '.......' + address.slice(address.length - 4, address.length)}
            </p>{' '}
          </button>
        ) : (
          <button onClick={() => doSome()} className="btn1 midle  ctd">
            {!userState.loading ? 'connect to wallet' : <PurlseLoader size={9} color="#fff" />}
          </button>
        )} */}

        {showButton && (
          <button onClick={() => doSome()} className="btn1 font-am midle ctd">
            {!userState.loading ? 'connect to wallet' : <PulseLoader size={9} color="#fff" />}
          </button>
        )}
      </Wrapper>
    </>
  );
};

export default Nav;

<button className="btn2 midle">
  <p className="text-xs">01XDEF89NHE.......XYZ</p>{' '}
</button>;
